//ContactForm.js
import React, { useState, useRef } from 'react';
import { Flex, Button, Input, Textarea } from "@chakra-ui/react";
import emailjs from 'emailjs-com';
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [restaurant, setRestaurant] = useState('');
    const [message, setMessage] = useState('');
    const [captchaValid, setCaptchaValid] = useState(false);
    const form = useRef();
    const reCaptchaRef = useRef(null);


    const SERVICE_ID = "FraysseGmail";
    const TEMPLATE_ID = "template_hm8ojlp";
    const PUBLIC_KEY = "cnAJQF38nZa7OWYJZ";
    const RECAPTCHA_KEY = "6Lfm2d8pAAAAAEXmCMcwjg-jDOAP8PAELlLdk7Or";



    const sendEmail = (e) => {
        
        e.preventDefault();

        if (!captchaValid) {
            toast.error("Please verify you are not a robot.");
            return;
        }

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            toast.success('Message sent successfully. We will get back to you as soon as possible.');
            setFirstName('');
            setLastName('');
            setEmail('');
            setRestaurant('');
            setPhone('');
            setMessage('');
            reCaptchaRef.current.reset(); 
            setCaptchaValid(false);
          }, (error) => {
            console.log(error.text);
            toast.error('Something went wrong!');
          });
    };
    
    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptchaValid(true);
    }
    
    

    return (
        <Flex as="form" onSubmit={sendEmail} gap={4} ref={form} id="contactForm">
        <Flex direction={{ desktop: "row", mobile: "column" }} gap={4} w="100%">
            <Flex direction="column" gap={2} flex={1}>
                <label htmlFor="firstName">First name</label>
                <Input
                    size="lg"
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder='John'
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
            </Flex>
            <Flex direction="column" gap={2} flex={1}>
                <label htmlFor="lastName">Name</label>
                <Input
                    size="lg"
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Smith"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                />
            </Flex>
            </Flex>
            <Flex direction={{ desktop: "row", mobile: "column" }} gap={4} w="100%">
            <Flex direction="column" gap={2} flex={1}>
                <label htmlFor="email">Email</label>
                <Input
                    size="lg"
                    type="text"
                    id="email"
                    name="email"
                    placeholder='john.smith@example.com'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Flex>
            <Flex direction="column" gap={2} flex={1}>
                <label htmlFor="phone">Phone</label>
                <PhoneInput
                    country={'lu'}
                    value={phone}
                    onChange={setPhone}
                    inputProps={{
                        name: 'phone', 
                        required: true,
                    }}
                    containerStyle={{ width: '100%', marginTop: '8px' }}
                    inputStyle={{
                    height: '45px',
                    border: '1px solid var(--chakra-colors-chakra-border-color)',
                    fontWeight: '400 !important',
                    fontFamily: 'sans-serif',
                    width: '100%',
                    }}
                />


            </Flex>
            </Flex>
            <Flex direction="column" gap={2} flex={1}>
                <label htmlFor="restaurant">Name of your restaurant</label>
                <Input
                    size="lg"
                    type="text"
                    id="restaurant"
                    name="restaurant"
                    placeholder='Name of your restaurant'
                    value={restaurant}
                    onChange={e => setRestaurant(e.target.value)} // Mise à jour de l'état à chaque changement de l'input
                />
            </Flex>
            <Flex direction="column" gap={2}>
                <label htmlFor="message">Message:</label>
                <Textarea
                    size="lg"
                    minHeight={"180px"}
                    h={"180px"}
                    id="message"
                    name="message"
                    fontSize="15px"
                    placeholder='Enter your message here'
                    value={message}
                    onChange={e => setMessage(e.target.value)} // Mise à jour de l'état à chaque changement de l'input
                />
            </Flex>
            <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={onChange}
                style={{ margin: "10px 0" }}
            />
            <Button type="submit" w="fit-content" bg='var(--brand)' color="white" size={"lg"}>Send request</Button>
        </Flex>
    );
}

export default ContactForm;
