import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, getDoc, collection, query, Timestamp, where, getDocs } from "firebase/firestore";
import { useShopContext } from "../shop/context/ShopContext";


const useSlots = () => {
  
    
    const { restaurantId, shopSettings, nextAvailableSlot, setNextAvailableSlot, setNextSlotDate } = useShopContext();
    const [openingHours, setOpeningHours] = useState([]);
    
    const [availableSlots, setAvailableSlots] = useState([]);
    

    useEffect(() => {
        const fetchOpeningHours = async () => {
          const restaurantRef = doc(db, "restaurants", restaurantId);
          const docSnap = await getDoc(restaurantRef);
          if (docSnap.exists() && Array.isArray(docSnap.data().openingHours)) {
            setOpeningHours(docSnap.data().openingHours);
          } else {
            setOpeningHours([]);
          }
        };
        fetchOpeningHours();
      }, [restaurantId]);
    
  
    
      const convertTimeToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(":").map(Number);
        return hours * 60 + minutes;
      };

      
      useEffect(() => {
        const initSlots = async () => {
          if (restaurantId && shopSettings?.general && openingHours?.length > 0) {
            const slots = await calculateAllAvailableSlots(
              restaurantId,
              openingHours,
              shopSettings.general.preparationTime,
              shopSettings.general.maxOrders
            );
            setAvailableSlots(slots);
          }
        };
      
        initSlots();
      }, [restaurantId, shopSettings, openingHours]);
      
    

      const calculateAllAvailableSlots = async (
        restaurantId,
        openingHours,
        preparationTime,
        maxOrders
      ) => {
        let now = new Date();
        let dayIndex = now.getDay();
        let allAvailableSlots = [];
        let firstAvailableSlot = null;
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      
        for (let daysChecked = 0; daysChecked < 2; daysChecked++) {
          const currentDayName = daysOfWeek[(dayIndex + daysChecked) % 7];
          const todaysOpeningHours = openingHours.find(oh => oh.day === currentDayName);
      
          if (todaysOpeningHours && todaysOpeningHours.slots.length > 0) {
            const sortedSlots = todaysOpeningHours.slots.sort((a, b) => {
              return convertTimeToMinutes(a.start) - convertTimeToMinutes(b.start);
            });
      
            for (const slot of sortedSlots) {
              const { start: openingStart, end: openingEnd } = slot;
              const openingStartInMinutes = convertTimeToMinutes(openingStart);
              let nowInMinutes = daysChecked === 0 ? (now.getHours() * 60 + now.getMinutes()) : 0;
              let startSearchTime = daysChecked === 0
                ? Math.max(nowInMinutes + preparationTime, openingStartInMinutes + preparationTime)
                : openingStartInMinutes + preparationTime;
              startSearchTime = Math.ceil(startSearchTime / 15) * 15;
      
              while (startSearchTime <= convertTimeToMinutes(openingEnd)) {
                const slotDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysChecked);
                slotDate.setHours(Math.floor(startSearchTime / 60), startSearchTime % 60, 0, 0);
      
                const isAvailable = await checkOrdersForSlot(
                  restaurantId,
                  currentDayName,
                  startSearchTime,
                  maxOrders,
                  daysChecked
                );
      
                if (isAvailable) {
                  const formattedSlot = formatTime(slotDate.getHours(), slotDate.getMinutes(), slotDate);
                  if (!firstAvailableSlot) {
                    firstAvailableSlot = formattedSlot; // Enregistrer le premier créneau disponible
                    setNextAvailableSlot(formattedSlot); // Mise à jour du contexte pour le premier créneau
                    setNextSlotDate(slotDate);
                  } else {
                    allAvailableSlots.push(formattedSlot);
                  }
                }
      
                startSearchTime += 15;
              }
            }
          }
        }
      
        return allAvailableSlots;
      };
      
     

      
      const formatDate = (date) => {
        const now = new Date();
        const tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
      
        if (date.toDateString() === now.toDateString()) {
          return "Today";
        } else if (date.toDateString() === tomorrow.toDateString()) {
          return "Tomorrow";
        } else {
          // Format pour les jours au-delà de demain, si nécessaire
          return date.toLocaleDateString();
        }
      };
      
      const formatTime = (hours, minutes, date) => {
        const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        const dateString = formatDate(date); // Utilisez la nouvelle fonction formatDate
        return `${dateString} ${timeString}`; // Combine les deux pour l'affichage
      };

      
    
      const checkOrdersForSlot = async (restaurantId, day, slotTime, maxOrders, daysToAdd) => {
        const ordersCollection = collection(db, `restaurants/${restaurantId}/orders`);
      
        // Utilisez daysToAdd pour calculer la date correcte pour la vérification du créneau
        const dateToCheck = new Date();
        dateToCheck.setDate(dateToCheck.getDate() + daysToAdd);
        
        const slotStartDateTime = new Date(
          dateToCheck.getFullYear(),
          dateToCheck.getMonth(),
          dateToCheck.getDate(),
          Math.floor(slotTime / 60),
          slotTime % 60,
          0,
          0
        );
      
        const slotStartTimeStamp = Timestamp.fromDate(new Date(slotStartDateTime.getTime() - 999));
      
        const slotEndDateTime = new Date(slotStartDateTime.getTime() + 15 * 60000 + 1);
        const slotEndTimeStamp = Timestamp.fromDate(slotEndDateTime);
      
        const ordersQuery = query(
          ordersCollection,
          where("scheduledTime", ">", slotStartTimeStamp),
          where("scheduledTime", "<=", slotEndTimeStamp)
        );
      
        const querySnapshot = await getDocs(ordersQuery);
        
        return querySnapshot.size < maxOrders;
      };



  return {
    calculateAllAvailableSlots,
    checkOrdersForSlot,
    openingHours,
    setOpeningHours,
    availableSlots
  };
};

export default useSlots;
