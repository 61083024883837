
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { auth } from '../../firebaseConfig'; // Assurez-vous d'importer votre configuration Firebase
import { signOut } from 'firebase/auth';
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, Button, Box, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import { ReceiptText, Wallet, BookUser } from 'lucide-react';




const Sidebar = ({ isOpen, onClose }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginRedirect = () => {
    onClose();
    sessionStorage.setItem('prevPath', location.pathname);
    navigate("/login");
  };


const handleLogout = async () => {
    try {
      await signOut(auth);
      onClose();
      sessionStorage.setItem('prevPath', location.pathname);
      console.log("Storing prevPath in sessionStorage:", location.pathname);
      navigate("/login");
    } catch (error) {
      console.error('Logout failed', error);
    }
  };  


  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={8}>
          {currentUser ? (
            <Flex direction="column" gap={8}>
              <Flex direction="row" gap={3}>
                
                <Box width="42px" height="42px" backgroundColor="var(--grey100)" borderRadius='42px' overflow="hidden">
                  {currentUser.photoURL &&
                  <Image src={currentUser.photoURL} alt="Profile" width="42px"/>
                  }
                </Box>
                <Flex direction="column">
                <h6>{currentUser.displayName || currentUser.email}</h6>
                <Text color="var(--grey500)">{currentUser.email}</Text>
                </Flex>
                </Flex>
       
              <Flex direction="column" gap={2}>
              <Link href="/account/orders"><Flex direction={"row"} alignItems="center" gap={2}><ReceiptText size={20} />Orders</Flex></Link>
              <Link href="/account/addresses"><Flex direction={"row"} alignItems="center" gap={2}><BookUser size={20} />Addresses</Flex></Link>
              <Link onClick={handleLogout}>Logout</Link>
              </Flex>
            </Flex>
          ) : (
            <>
              <p>You are not logged.</p>
              <Link onClick={handleLoginRedirect}>Login</Link>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
