import React, { useState } from "react";
import LanguageSwitcher from "../../frontend/components/LanguageSwitcher";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Flex } from "@chakra-ui/react";
import { Menu } from "lucide-react";

const ShopHeader = ({ styles, logoUrl, restaurantId, onPlaceSelected }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  return (
    <header className={styles.shopHeader}>
      <div className={styles.container}>
        <Link
          to={`/${restaurantId}/shop`}
          className={styles.logo}
          title="Back to home">
          <img src={logoUrl} alt="Logo" />
        </Link>

        <Flex direction="row" gap={2} className={styles.right}>
          {/* <LanguageSwitcher
            restaurantId={restaurantId}
            selectedLanguage={selectedLanguage}
            defaultLanguage={defaultLanguage}
            secondaryLanguages={secondaryLanguages}
            onLanguageChange={handleLanguageChange} 
            styles={styles}
          />
        */}
          

          <div className="sidebarToggle" onClick={toggleDrawer}>
            <Menu />
          </div>
        </Flex>
      </div>
      <Sidebar
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
      />
    </header>
  );
};

export default ShopHeader;
