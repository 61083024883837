// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import { Flex } from "@chakra-ui/react";

const NotFound = ( {restaurantId} ) => (
  <Flex className="NotFoundContainer" position="fixed" top={0} zIndex={100} w="100vw" h="100vh">
    <div className="NotFoundContent">
      <h1>404 - Oups!</h1>
      <p>Il semblerait que la page que vous cherchez ait disparu ou n'ait jamais existé.</p>
      <Link to={`/${restaurantId}/menu`} className="HomeLink">Retourner à l'accueil</Link>
    </div>
  </Flex>
);

export default NotFound;
