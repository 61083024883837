import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./shop/context/AuthContext";
import { ChakraProvider } from "@chakra-ui/react";
import useBreakpoints from "./hooks/useBreakpoints";
import MainProvider from "./shop/MainProvider";
import "./App.css";
import Spinner from "./components/Spinner";
import MenuFrontend from "./frontend/MenuFrontend";
import PrivateRoute from "./shop/components/PrivateRoute";
import ShopActivationRoute from "./shop/ShopActivationRoute";
import CheckoutActivationRoute from "./shop/CheckoutActivationRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "./shop/Success";
import LoginPage from "./shop/components/LoginPage";
import UserOrders from "./shop/components/account/UserOrders";
import GuddenLayout from "./GuddenLayout";
import Home from "./Home";
import { initGA, logPageView } from './Analytics';


function App() {
  const [loading, setLoading] = useState(false);
  const { theme } = useBreakpoints();
  function TrackPageViews() {
    const location = useLocation();
  
    useEffect(() => {
      initGA('G-VBHEBBSTBC');
    }, []);
  
    useEffect(() => {
      logPageView();
    }, [location]);
  
    return null;  // Ce composant ne rend rien visuellement.
  }

  return (
    <ChakraProvider theme={theme}> 
      <AuthProvider loading={loading} setLoading={setLoading}>
        <Router>
         <TrackPageViews />
          <div className="App">
            <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
              closeOnClick
              theme="colored"
              position="top-center"
            />
            {loading ? (
              <Spinner />
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                      <Home />
                  }
                />
                   <Route
                  path="/login"
                  element={
                    <GuddenLayout>
                      <LoginPage />
                    </GuddenLayout>
                  }
                />
                <Route
                  path="/account/orders"
                  element={
                    <PrivateRoute>
                      <GuddenLayout>
                        <UserOrders />
                      </GuddenLayout>
                    </PrivateRoute>
                  }
                />
                
                <Route path="/:restaurantId/menu" element={<MenuFrontend />} />
                <Route
                  path="/:restaurantId/shop"
                  element={
                    <MainProvider>
                      <ShopActivationRoute />
                    </MainProvider>
                  }
                />

                <Route
                  path="/:restaurantId/shop/checkout"
                  element={
                    <PrivateRoute>
                      <MainProvider>
                        <CheckoutActivationRoute />
                      </MainProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:restaurantId/shop/success"
                  element={
                    <MainProvider>
                      <Success />
                    </MainProvider>
                  }
                />

             
              </Routes>
            )}
          </div>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
