const useTranslations = ( ) => {
	
  const translations = {
    filters: {
      fr: "Filtres",
      en: "Filters",
      de: "Filter",
      it: "Filtri",
      lb: "Filter",
      pt: "Filtros"
    },
    allergens: {
      fr: "Allergènes",
      en: "Allergens",
      de: "Allergene",
      it: "Allergeni",
      lb: "Allergen",
      pt: "Alergénos",
    },
    askWaiter: {
      fr: "Veuillez demander à votre serveur",
      en: "Please ask your waiter",
      de: "Bitte fragen Sie Ihren Kellner",
      it: "Per favore chiedi al tuo cameriere",
      lb: "Frot w.e.g. ären Serveur",
      pt: "Por favor, pergunte ao seu garçom",
    },
    noAllergens: {
      fr: "Aucun allergène.",
      en: "No allergens.",
      de: "Keine Allergene",
      it: "Nessun allergene",
      lb: "Keng Allergen",
      pt: "Sem alérgenos",
    },
    options: {
      fr: "Options",
      en: "Options",
      de: "Optionen",
      it: "Opzioni",
      lb: "Optiounen",
      pt: "Opções",
    },
    price: {
      fr: "Prix",
      en: "Price",
      de: "rix",
      it: "Prezzo",
      lb: "Präis",
      pt: "Preço",
    }
  };
  
	return {
        translations
	};
};

export default useTranslations;
