import ShopList from './components/ShopList';
import { useShopContext } from './context/ShopContext';


const ShopFrontend = ({ restaurantId }) => {
  
  
  const { cartItems, setCartItems, updateItemQuantity, addItemToCart, cartUpdated, 
    setCartUpdated, calculateTotal, clearCart, styles, frontendTags, menuItems, subCategories } = useShopContext(restaurantId); 
 
    
  return (
  <>
    
    <ShopList 
          restaurantId={restaurantId}
          menuItems={menuItems} 
          subCategories={subCategories.filter(cat => !cat.isUncategorized)}
          styles={styles}
          tags={frontendTags}
          addItemToCart={addItemToCart}
          cartUpdated={cartUpdated}
          setCartUpdated={setCartUpdated}
          cartItems={cartItems}
          setCartItems={setCartItems}
          updateItemQuantity={updateItemQuantity}
          calculateTotal={calculateTotal}
          clearCart={clearCart}
        />
     
</>




  );
}

export default ShopFrontend;
