// CheckoutElements.js
import React, { useState, useEffect } from "react";
import Checkout from "./components/Checkout";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useShopContext } from "./context/ShopContext";
import { Flex } from "@chakra-ui/react";


const CheckoutElements = ({ restaurantId }) => {
  const { stripeKeys, loadStripeKeys, shopSettings } = useShopContext();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    loadStripeKeys();
  }, [restaurantId]);
  
  
  useEffect(() => {
    if (stripeKeys && stripeKeys.PublicTest) {
      setStripePromise(loadStripe(stripeKeys.PublicTest)); // Utilisation de la clé publique pour l'initialisation de Stripe
    }
  }, [stripeKeys]);
  
  if (!stripePromise) return <Flex justifyContent="center" height='100%'>Sorry, our shop is deactivated.</Flex>;

  return (
    <Elements stripe={stripePromise}>
      <Checkout restaurantId={restaurantId} />
    </Elements>
  );
};

export default CheckoutElements;
