import { useState, useEffect } from "react";
import Modal from "react-modal";
import { db } from "../../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore"; 
import { useAuth } from "../../context/AuthContext";
import { Flex, Stack, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";


Modal.setAppElement("#root");

const customStyles = {
  content: {
    height: "100%",
    width: "100%",
    maxWidth: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
  },
};

const UserDetails = ({ currentUser, isOpen, onClose }) => {
  const { updateUserDetails } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
      if (currentUser) {
          setFirstName(currentUser.displayName?.split(" ")[0] || "");
          setLastName(currentUser.displayName?.split(" ")[1] || "");
          setPhone(currentUser.phone || "");
          setAddress(currentUser.address || "");
      }
  }, [currentUser]);
  
  
    const saveUserDetails = async (e) => {
    e.preventDefault();
  
    const userRef = doc(db, "users", currentUser.uid);
    try {
      await updateDoc(userRef, {
        displayName: `${firstName} ${lastName}`,
        phone: phone,
        address: address
      });
      updateUserDetails({
        displayName: `${firstName} ${lastName}`,
        phone: phone,
        address: address
      });
      onClose();
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const hasChanges = () => {
    if (!currentUser) {
        return false; // Retourne false ou un autre comportement approprié si currentUser est null
    }
    const initialFirstName = currentUser.displayName?.split(" ")[0] || "";
    const initialLastName = currentUser.displayName?.split(" ")[1] || ""; // Gère le cas où il n'y a pas de lastName
    const initialPhone = currentUser.phone || "";
    const initialAddress = currentUser.address || "";
    return firstName !== initialFirstName || lastName !== initialLastName || phone !== initialPhone || address !== initialAddress;
};
  
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      style={customStyles}>
      <div className="modalContent">
        <div className="modalHeader">
          <span className="close" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                fill="white"
              />
            </svg>
          </span>
          <h4>Modify personal details</h4>
        </div>
        <Flex direction="column" p="24px" gap={8}>
          <Stack gap={4}>
          <FormControl isRequired>
            <FormLabel fontSize="13px">First name</FormLabel>
            <Input
              size="lg"
              variant="filled"
              focusBorderColor="#121212"
              name="firstName"
              type="text"
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
        </FormControl>
        <FormControl isRequired>
            <FormLabel fontSize="13px">Last name</FormLabel>
            <Input
              size="lg"
              variant="filled"
              name="lastName"
              type="text"
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
        </FormControl>
        <FormControl isRequired>
            <FormLabel fontSize="13px">Phone number</FormLabel>    
            <Input
              size="lg"
              variant="filled"
              name="phone"
              type="tel"
              placeholder="Phone"
              focusBorderColor="#121212"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
        </FormControl>
        <FormControl>
            <FormLabel fontSize="13px">Address</FormLabel>  
            <Input
              size="lg"
              variant="filled"
              name="phone"
              type="text"
              placeholder="Address"
              focusBorderColor="#121212"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
        </FormControl>
          </Stack>
          <Button size="lg" isDisabled={!hasChanges()} className="primary" onClick={saveUserDetails}>Save Changes</Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default UserDetails;
