import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, addDoc, Timestamp, getDocs, query, where, updateDoc } from "firebase/firestore";
import { Flex, Grid, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Input, Textarea, Button, Modal, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Text, Checkbox, Spinner, FormControl, FormLabel } from '@chakra-ui/react';
import { CalendarPlus, Utensils, Calendar, Clock, Heart, User, X } from "lucide-react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import useRestaurantData from '../../../hooks/useRestaurantData';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from "react-toastify";

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  guests: 2,
  preference: '',
  scheduledTime: '',
  date: new Date(),
  newsletter: false,
};

  const Reservation = ({ restaurantId, serviceDuration, settings, maxGuests }) => {
   
   
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const [formData, setFormData] = useState(initialFormData);
    
    
    const { restaurant } = useRestaurantData(restaurantId);
  
    const [slotsAreLoading, setSlotsAreLoading] = useState(false);
    const [selectedGuestNumber, setSelectedGuestNumber] = useState(2);
    const openingHours = restaurant?.openingHours || [];
    const [timeSlots, setTimeSlots] = useState([]);
    const [slotsAvailable, setSlotsAvailable] = useState(true);  // Nouvelle variable d'état
    const [activeIndex, setActiveIndex] = useState();

   

    const handleGuestsSelection = (guestsNumber) => {
        setSelectedGuestNumber(guestsNumber);
        setFormData({ ...formData, guests: guestsNumber.toString() });
        setActiveIndex(1);
    };

    const isDateAvailable = (date) => {
        const dayName = date.toLocaleDateString('en-us', { weekday: 'long' });
        const daySettings = openingHours.find((day) => day.day === dayName);
        return !!daySettings?.slots?.length;
      };

   

      const generateTimeSlotsForDay = (day, slots) => {
        const timeSlots = [];
        slots.forEach((slot) => {
          if (slot.start && slot.end) {
            let currentTime = new Date();
            currentTime.setHours(...slot.start.split(':'), 0, 0);
            const endTime = new Date();
            endTime.setHours(...slot.end.split(':'), 0, 0);
            
            while(currentTime < endTime) {
              timeSlots.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }));
              currentTime = new Date(currentTime.getTime() + 15 * 60000);
            }
          }
        });
        return timeSlots;
      };
      
      
      const selectedDayName = formData.date.toLocaleDateString('en-us', { weekday: 'long' }); // Get the selected day name
      const openingHoursForDay = openingHours.find((day) => day.day === selectedDayName)?.slots || [];
      
      useEffect(() => {
        setTimeSlots(generateTimeSlotsForDay(selectedDayName, openingHoursForDay));
      }, [formData.date, openingHours]);
      

      
      const isTimeSlotPassed = (timeSlot) => {
        const currentTime = new Date();
        const [hours, minutes] = timeSlot.time.split(':');
        const timeSlotDate = new Date(formData.date);
        timeSlotDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);    
        return currentTime > timeSlotDate;
    };
    



      const getSelectedDateString = (date) => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
      
        if (date.toDateString() === today.toDateString()) {
          return 'Today';
        } else if (date.toDateString() === tomorrow.toDateString()) {
          return 'Tomorrow';
        } else {
          return date.toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
        }
      };

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormData(prevState => ({
          ...prevState,
          [name]: newValue
        }));
      };
      
  
      

      const findAvailableTables = async (guests, date, time) => {
        guests = Number(guests);
        const startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(time.split(":")[0]), parseInt(time.split(":")[1]), 0);
        const endTime = new Date(startTime.getTime() + (serviceDuration * 60000));
    
        const tablesSnapshot = await getDocs(collection(db, `restaurants/${restaurantId}/tables`));
        
        let tableAvailability = new Map();
    
        tablesSnapshot.docs.forEach(doc => {
          let data = doc.data();
          const tableCapacity = Number(data.capacity);  // Convertir la capacité en nombre
          tableAvailability.set(doc.id, {
              ...data,
              id: doc.id,
              available: true,
              capacity: tableCapacity,
              isCombinationEmpty: !data.combination || data.combination.length === 0
          });
      });

      
        const reservationsSnapshot = await getDocs(query(
          collection(db, `restaurants/${restaurantId}/reservations`),
          where("scheduledTime", "<=", Timestamp.fromDate(endTime))
      ));

        reservationsSnapshot.docs.forEach(doc => {
          const reservation = doc.data();
          const reservationEndTime = new Date(reservation.scheduledTime.toDate().getTime() + serviceDuration * 60000);
          
          reservationEndTime.setMilliseconds(0);
          startTime.setMilliseconds(0);
          
          reservation.tables.forEach(tableId => {
            if (reservationEndTime > startTime) {
              tableAvailability.get(tableId).available = false;
            }
          });
        });
      
      
        let availableTables = Array.from(tableAvailability.values())
        .filter(table => table.available)
        .sort((a, b) => a.isCombinationEmpty === b.isCombinationEmpty ? 0 : a.isCombinationEmpty ? -1 : 1); // Prioritize tables with no combinations
    
      return findTablesForGuests(availableTables, guests);

      };

      function findTablesForGuests(tables, guestsNeeded) {
        
        // Trier toutes les tables par leur capacité, ascendante, pour que les plus petites adéquates soient considérées en premier
        tables.sort((a, b) => a.capacity - b.capacity);
    
        // Filtrer pour trouver une table qui correspond exactement ou est plus grande, avec priorité aux tables sans combinaison
        let suitableTable = tables.find(table => table.capacity >= guestsNeeded && table.isCombinationEmpty);
    
        // Si une table convenable est trouvée, retourner immédiatement cette table dans un tableau
        if (suitableTable) {
            return [suitableTable];
        }
    
        // Si aucune table sans combinaison n'est adéquate, essayer de trouver une table sans condition de combinaison
        suitableTable = tables.find(table => table.capacity >= guestsNeeded);
        if (suitableTable) {
            return [suitableTable];
        }
    
        // Si toujours rien, essayer les combinaisons si nécessaire
        return findValidTableCombinations(tables, guestsNeeded);
    }
    
    
        
       
      function findValidTableCombinations(tables, guestsNeeded) {
        let combinations = [];
      
        // Build combinations
        tables.forEach(table => {
          if (table.combination.length > 0) {
            table.combination.forEach(combinationId => {
              let combinedTable = tables.find(t => t.id === combinationId && t.available);
              if (combinedTable) {
                let combinedCapacity = table.capacity + combinedTable.capacity;
                if (combinedCapacity >= guestsNeeded) {
                  let comboKey = [table.id, combinedTable.id].sort().join('|');
                  combinations[comboKey] = combinations[comboKey] || [];
                  combinations[comboKey].push([table, combinedTable]);
                }
              }
            });
          }
        });
      
        // Flatten combinations and remove duplicates
        let uniqueCombinations = Object.values(combinations).map(combo => combo[0]);
        return uniqueCombinations.length > 0 ? uniqueCombinations : [];
      }
      
      
      
    const updateTimeSlots = async () => {
      setSlotsAreLoading(true);
      const availableTimeSlots = [];
      const timeSlots = generateTimeSlotsForDay(selectedDayName, openingHoursForDay);
      for (let timeSlot of timeSlots) {
          const tables = await findAvailableTables(formData.guests, formData.date, timeSlot);
          let slotInfo = {
              time: timeSlot,
              available: tables.length > 0
          };
          availableTimeSlots.push(slotInfo);
      }
      setTimeSlots(availableTimeSlots);
      setSlotsAreLoading(false);
      setSlotsAvailable(availableTimeSlots.some(slot => slot.available));  // Mettre à jour slotsAvailable
  };
  
  
    
    
    useEffect(() => {
      updateTimeSlots();
    }, [formData.guests, formData.date]);



  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const [selectedHour, selectedMinute] = formData.scheduledTime.split(":");
      const reservationDateTime = new Date(formData.date);
      reservationDateTime.setHours(parseInt(selectedHour, 10), parseInt(selectedMinute, 10), 0, 0);
      const timestamp = Timestamp.fromDate(reservationDateTime);
    
      try {
        const customersRef = collection(db, `restaurants/${restaurantId}/customers`);
        const customerQuery = query(customersRef, where("email", "==", formData.email));
        const customerSnapshot = await getDocs(customerQuery);
    
        let customerId;

        if (!customerSnapshot.empty) {
          const customerDoc = customerSnapshot.docs[0];
          customerId = customerDoc.id;
          // Mise à jour du document client si nécessaire
          await updateDoc(customerDoc.ref, {
            [`phoneNumbers.${formData.phone}`]: true
          });
        } else {
          // Nouveau client
          const customerRef = await addDoc(customersRef, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumbers: {
              [formData.phone]: true
            },
            newsletter: formData.newsletter
          });
          customerId = customerRef.id;
        }
    
        // Find available tables
        const availableTables = await findAvailableTables(formData.guests, formData.date, formData.scheduledTime);
        const tableIds = availableTables.flat(Infinity).map(table => table.id).filter(id => id !== undefined);
    
        if (tableIds.length === 0) {
          toast.error("No tables available for the selected time slot.");
          return;
        }
    
        // Create reservation
        const docRef = await addDoc(collection(db, `restaurants/${restaurantId}/reservations`), {
          customer: {
            id: customerId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: `+${formData.phone}`,
          },
          guests: formData.guests,
          tables: tableIds,
          preference: formData.preference,
          scheduledTime: timestamp,
          status: settings.reservationsSettings?.autoConfirm ? 'confirmed' : 'pending',
          newsletter: formData.newsletter,
        });
    
        toast.success("Your reservation request has been successfully submitted. Our team will review the details and confirm your booking promptly.");
        setFormData(initialFormData);
        onClose();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };
    
    
    
    
    



    return (
      <>
        <Button position="fixed" bottom="4" right="4" bg="black" color="white" onClick={onOpen} zIndex={1} size="md" fontWeight="500" fontSize='14px' border="1px solid rgba(255,255,255,.24)">
          <CalendarPlus size={14} strokeWidth="2" /> Reserve a table
        </Button>
  
        <Modal isOpen={isOpen} onClose={onClose} >
          <ModalContent bg="#121212"  position="fixed"  bottom="4" right="4" m={0} border="1px solid rgba(255,255,255,.24)" w="calc(428px - var(--chakra-space-8))" maxWidth="calc(100% - var(--chakra-space-8))">
            <ModalHeader display="flex" justifyContent="space-between" alignItems="center" color="white" fontSize="18px">
                Reserve a table
                <X onClick={onClose} size={16} color="rgba(255,255,255,.5)" cursor="pointer" className='closeIcon'/>
            </ModalHeader>
            <form onSubmit={handleSubmit}>
            <ModalBody>
                
            <Accordion allowToggle index={activeIndex} onChange={(index) => setActiveIndex(index)}>
                <AccordionItem borderColor='rgba(255,255,255,0)'>
                        <h6>
                        <AccordionButton>
                            
                            <Flex flex='1' gap={3} alignItems="center">
                                <Utensils size={16} color="rgba(255,255,255,.5)"/>
                                {selectedGuestNumber} guests
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                        <AccordionPanel pb={4}>
                  <Grid gridTemplateColumns="repeat(5, 1fr)" gridGap={2}>
                  {[...Array(maxGuests)].map((_, i) => (
                      <Button 
                        bg={selectedGuestNumber === i + 1 ? 'white' : 'transparent'}
                        color={selectedGuestNumber === i + 1 ? '#121212' : 'white'}
                        border="1px solid rgba(255,255,255,.24)"
                        key={i} 
                        onClick={() => handleGuestsSelection(i + 1)}>
                        {i + 1}
                      </Button>
                    ))}
                     <Button 
                        bg="transparent"
                        color="white"
                        border="1px solid rgba(255,255,255,.24)"
                        onClick={() => toast.error(`Please contact us by phone for reservations over ${maxGuests} guests`)}
                      >
                        +
                      </Button>
                  </Grid>
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderColor='rgba(255,255,255,.24)'>
                  <AccordionButton>
                  <Flex flex='1' gap={3} alignItems="center">
                                <Calendar size={16} color="rgba(255,255,255,.5)"/>
                                {getSelectedDateString(formData.date)} 
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <DatePicker
                      selected={formData.date}
                      onChange={(date) => {
                        setFormData({ ...formData, date });
                        setActiveIndex(activeIndex + 1);
                      }}
                      dateFormat="MMMM d, yyyy"
                      minDate={new Date()}
                      filterDate={isDateAvailable}
                      inline
                    />
                  </AccordionPanel>
                </AccordionItem>
                

                <AccordionItem borderColor='rgba(255,255,255,.24)'>
                        <h6>
                        <AccordionButton>
                        <Flex flex='1' gap={3} alignItems="center">
                                <Clock size={16} color="rgba(255,255,255,.5)"/>
                                {formData.scheduledTime ? formData.scheduledTime : "Time"}
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                      
                      
                    {slotsAreLoading ? (
  <Flex justifyContent="center" alignItems="center" height="32px">
    <Spinner size="lg" color="white" />
  </Flex>
) : (
  slotsAvailable ? (
    <Grid templateColumns="repeat(3, 1fr)" gap={2} maxHeight={"260px"} overflowY="auto" pl={4} pr={4}>
      {timeSlots.map(({ time, available }, index) => (
        <Button
          size="sm"
          fontSize="14px"
          fontWeight="400"
          key={index}
          onClick={() => available && setFormData(prevState => ({ ...prevState, scheduledTime: time, activeIndex: prevState.activeIndex + 1 }))}
          bg={formData.scheduledTime === time ? "white" : "transparent"}
          color={formData.scheduledTime === time ? "#121212" : "white"}
          border="1px solid rgba(255,255,255,.24)"
          isDisabled={!available || isTimeSlotPassed({ time })}
        >
          {time}
        </Button>
      ))}
    </Grid>
  ) : (
    <Text color="white" fontSize="16px" textAlign="center" p={4}>
      No availability for {selectedGuestNumber} guests on the selected day.
    </Text>
  )
)}
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderColor='rgba(255,255,255,.24)'>
                        <h6>
                        <AccordionButton>
                        <Flex flex='1' gap={3} alignItems="center">
                                <Heart size={16} color="rgba(255,255,255,.5)"/>
                                Preferences
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                    <Textarea placeholder="Any preferences?" name="preference" onChange={handleChange} value={formData.preference} color="white"/>

                    </AccordionPanel>
                </AccordionItem>


                <AccordionItem borderColor='rgba(255,255,255,.24)' borderBottom="none">
                        <h6>
                        <AccordionButton>
                            <Flex flex='1' gap={3} alignItems="center">
                                <User size={16} color="rgba(255,255,255,.5)"/>
                                {formData.firstName && formData.lastName ? `${formData.firstName} ${formData.lastName}` : "Your details" }
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                    <Flex direction={"column"} gap={2}>
                    <Input border="1px solid rgba(255,255,255,.24)" required color="white" placeholder="First Name" name="firstName" onChange={handleChange} value={formData.firstName}  />
                    <Input border="1px solid rgba(255,255,255,.24)" required color="white" placeholder="Last Name" name="lastName" onChange={handleChange} value={formData.lastName}  />
                    <Input border="1px solid rgba(255,255,255,.24)" required color="white" placeholder="Email" name="email" type="email" onChange={handleChange} value={formData.email}  />
                    <PhoneInput
                        country={'lu'}
                        value={formData.phone}
                        onChange={(phone) => setFormData({ ...formData, phone: phone || '' })}
                        containerStyle={{ width: '100%', marginTop: '8px' }}
                        inputStyle={{
                          width: '100%',
                          height: '37.5px',
                          border: '1px solid rgba(255,255,255,.24)',
                          background: 'transparent',
                          color: 'white',
                          fontWeight: '400 !important',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      />

                    
                    <Checkbox color="white" name="newsletter" isChecked={formData.newsletter} onChange={handleChange}>Subscribe to our newsletter</Checkbox>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
                    


                </Accordion>
              </ModalBody>
              <ModalFooter display="flex" flexDirection={"column"} gap={2}>
                <Button bg="var(--restaurantColor)" color="white" type="submit" size="md" w="100%">Reserve</Button>
                <Text color="rgba(255,255,255,.5)" fontSize="11px">Powered by gudden.app</Text>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default Reservation;