import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import UserDetails from '../components/account/UserDetails';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children}) => {
  
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const userRef = doc(db, "users", userAuth.uid);
        const docSnap = await getDoc(userRef);
    
        if (docSnap.exists()) {
          setCurrentUser({
            uid: userAuth.uid,
            email: userAuth.email,
            displayName: userAuth.displayName,
            photoURL: userAuth.photoURL,
            role: userAuth.role,
            phone: userAuth.phone,
            address: userAuth.address,
            ...docSnap.data(),
          });
        } else {
          setCurrentUser(userAuth);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
}, []);

  const login = (user) => {
      setCurrentUser(user);
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
  const toggleUserDetailsModal = () => {
    setIsUserDetailsOpen(!isUserDetailsOpen);
  };

  const updateUserDetails = (updatedFields) => {
    setCurrentUser((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };
  

  
  

  const value = {
    currentUser,
    login,
    logout,
    isAuthenticated: !!currentUser,
    loading,
    isUserDetailsOpen,
    setIsUserDetailsOpen,
    toggleUserDetailsModal,
    updateUserDetails
  };

  

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {!loading && <UserDetails isOpen={isUserDetailsOpen} onClose={toggleUserDetailsModal} currentUser={currentUser} />}
    </AuthContext.Provider>
  );
};