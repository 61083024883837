import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const useRestaurantData = ( ) => {
  const {restaurantId} = useParams();
  const [restaurant, setRestaurant] = useState([]);
  
  
  useEffect(() => {
    const fetchRestaurantData = async () => {
      // Si vous souhaitez récupérer un seul document :
      const docRef = doc(db, "restaurants", restaurantId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        setRestaurant({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
      }

    };
    
    if (restaurantId) { // S'assurer que restaurantId n'est pas undefined ou nul
      fetchRestaurantData();
    }
  }, [restaurantId]); // Ajouter restaurantId comme dépendance de useEffect
  


  return {
    restaurant,
  }
};

export default useRestaurantData;
