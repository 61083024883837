import { useRef } from 'react';

const LanguageSwitcher = ({ selectedLanguage, defaultLanguage, secondaryLanguages, onLanguageChange, styles }) => {
    
    const languageSwitcherRef = useRef(null);
    const allLanguages = [defaultLanguage, ...secondaryLanguages];

    

    return (
        <ul ref={languageSwitcherRef} className={styles.languageSwitcher}>
            {allLanguages.map(lang => (
                <li key={lang.value} onClick={() => onLanguageChange(lang)} className={`${styles.languageItem} ${selectedLanguage === lang.value ? styles.active : ''}`}>
                        <img src={lang.flag} alt={lang.label} width="100%" />
                </li>
            ))}
        </ul>
    );
};

export default LanguageSwitcher;
