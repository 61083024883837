import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useShopContext } from "../context/ShopContext";
import CartContent from "./CartContent";
import DeliveryModeSwitcher from "./DeliveryModeSwitcher";
import AddressAutoComplete from "./checkout/AddressAutoComplete";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import {
  Textarea,
  Box,
  Flex,
  Stack,
  Button,
  Divider,
  Link,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { User, Store } from "lucide-react";
import { useAuth } from "../context/AuthContext";
import Map from "./checkout/Map";
import NextAvailableSlot from "./checkout/NextAvailableSlot";
import Planification from "./checkout/Planification";

const Checkout = ({ restaurantId }) => {
  const { currentUser, toggleUserDetailsModal } = useAuth();
  const {
    cartItems,
    styles,
    shopSettings,
    formData,
    setFormData,
    submitOrder,
    totalWithTip,
    deliveryMode,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isCheckoutDisabledCheckout,
    isDeliveryZoneValid,
    calculateTotal,
    clearCart,
    nextAvailableSlot,
    nextSlotDate,
    scheduledSlotDate,
    isAddressSelected,
  } = useShopContext();


  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [stripeFieldsComplete, setStripeFieldsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlanningTime, setIsPlanningTime] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState("");


  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");
  const stripe = useStripe();
  const elements = useElements();

  function decomposeDisplayName(displayName) {
    const names = displayName.split(" ");
    const firstName = names[0];
    let lastName = "";

    if (names.length > 1) {
      lastName = names.slice(1).join(" ");
    }

    return { firstName, lastName };
  }

  useEffect(() => {
    if (currentUser && currentUser.displayName) {
      const { firstName, lastName } = decomposeDisplayName(
        currentUser.displayName
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        customerDetails: {
          ...prevFormData.customerDetails,
          firstName: firstName,
          lastName: lastName,
          email: currentUser.email,
          userId: currentUser.uid,
          phone: currentUser.phone,
        },
      }));
    }
  }, [currentUser, setFormData]);

  const checkoutDisabled = isCheckoutDisabledCheckout();

  const navigate = useNavigate();
  useEffect(() => {
    if (cartItems.length === 0) {
      navigate(`/${restaurantId}/shop`);
    }
  }, [cartItems, navigate, restaurantId]);

  const addressInputStyle =
    deliveryMode === "delivery" ? {} : { display: "none" };

  const handleCardDetailsChange = (event) => {
    setStripeFieldsComplete(event.complete);
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage("");
    }
  };

  const handlePayClick = async () => {
    setIsSubmittingPayment(true);
    const orderData = {
      ...formData,
      cartItems,
      subTotal: calculateTotal(),
      total: totalWithTip(),
      paymentMethod: selectedPaymentMethod,
      scheduledTime: scheduledSlotDate || nextSlotDate,
      deliveryNote: deliveryMode === "delivery" ? deliveryNote : ""
    };

    try {
        let orderResult;
        if (selectedPaymentMethod === "till") {
            orderResult = await submitOrder(orderData, "unpaid");
        } else if (selectedPaymentMethod === "online") {
            orderResult = await handleStripePayment(orderData); // Cette fonction doit maintenant retourner le résultat de submitOrder
        } else {
            throw new Error("Payment method undefined");
        }
        if (orderResult) {
            navigate(`/${restaurantId}/shop/success`, { state: orderResult });
        }
    } catch (error) {
        console.error(error);
        setIsSubmittingPayment(false);
        toast.error("An error occurred during the payment process.");
    }
  };



  const fetchClientSecret = async () => {
    const totalAmount = parseFloat(totalWithTip().replace(",", ".")) * 100;
    try {
      const result = await createPaymentIntent({
        amount: totalAmount,
        restaurantId: restaurantId,
      });
      return result.data.clientSecret;
    } catch (error) {
      console.error("Erreur lors de l'obtention du clientSecret:", error);
      toast.error("Problem fetching payment information. Please try again.");
      setIsSubmittingPayment(false);
    }
  };

  const handleStripePayment = async (orderData) => {
    if (!stripeFieldsComplete) {
      setErrorMessage("Please fill in all required card information.");
      setIsSubmittingPayment(false);
      return;
    }

    const clientSecret = await fetchClientSecret();
    if (clientSecret) {
      const { paymentMethodId, error } = await submitStripePayment(
        clientSecret
      );
      if (error) {
        toast.error("Payment failed: " + error, { autoClose: false });
        setIsSubmittingPayment(false);
      } else {
        await handlePaymentSuccess(paymentMethodId, orderData);
      }
    } else {
      setIsSubmittingPayment(false);
    }
  };

  const submitStripePayment = async (clientSecret) => {
    if (!stripe || !elements) {
      console.error("Stripe.js hasn't loaded yet.");
      return { error: "Stripe.js hasn't loaded yet." };
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${formData.customerDetails.firstName} ${formData.customerDetails.lastName}`,
            email: formData.customerDetails.email,
          },
        },
      }
    );

    if (error) {
      console.error(`Payment failed: ${error.message}`);
      return { error: error.message };
    } else {
      console.log("Payment succeeded:", paymentIntent.id);
      return { paymentMethodId: paymentIntent.id }; // Retourne l'ID du paiement pour une utilisation ultérieure
    }
  };

  const handlePaymentSuccess = async (paymentMethodId, orderData) => {
    orderData.paymentMethodId = paymentMethodId;
    orderData.paymentStatus = "paid";
    const orderDataComplete = await submitOrder(orderData, "paid");
    navigate(`/${restaurantId}/shop/success`, { state: orderDataComplete });
  };

  const [isMapLoading, setIsMapLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMapLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <main className={styles.wrapper} style={{ background: "var(--grey100)" }}>
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        maxW="1100px"
        gap={{ desktop: 8, mobile: 0 }}
        p={{ desktop: "48px 0", mobile: "0" }}
        className={`${styles.container} ${styles.checkout}`}>
        <Flex
          direction="column"
          width={{ desktop: "60%", mobile: "100%" }}
          borderRadius={{ desktop: "8px", mobile: "0" }}
          overflow={"hidden"}>
          <Flex direction="column" background="white" p={8} gap={4}>
            <Flex direction="row" justifyContent="space-between">
              <h3>
                {deliveryMode === "delivery"
                  ? "Delivery details"
                  : "Pickup details"}{" "}
              </h3>
              <DeliveryModeSwitcher />
            </Flex>

            {deliveryMode === "pickup" && (
              <>
                <Flex direction="column" gap={4}>
                  {isMapLoading ? (
                    <Skeleton height="250px" />
                  ) : (
                    <Map
                      address={shopSettings.contact.address}
                      title={restaurantId}
                    />
                  )}
                  <Flex direction="row" justifyContent="space-between">
                    <Flex direction="row" align="center" gap={4}>
                      <Store size={24} />
                      <Stack gap={0}>
                        <Text style={{ textTransform: "capitalize" }}>
                          {restaurantId}
                        </Text>
                        <Text
                          style={{ fontSize: "14px", color: "var(--grey500)" }}>
                          {shopSettings.contact.address}
                        </Text>
                      </Stack>
                    </Flex>
                    <Link href={`tel:${shopSettings.contact.phoneNumber}`}>
                      <Button size="sm" className="secondary btn sm">
                        Call restaurant
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </>
            )}

            <Flex className="field" style={addressInputStyle}>
              <AddressAutoComplete />
            </Flex>
            {deliveryMode === "delivery" && (
              <Flex direction="column" background="white" gap={2}>
                <h5>Add a note for the delivery</h5>
                <div className="timeOptions">
                <Textarea className="option"
                    value={deliveryNote}
                    onChange={(e) => setDeliveryNote(e.target.value)}
                    placeholder="Eg: Floor number or company name"
                    isDisabled={deliveryMode !== "delivery"}
                  />
                </div>
              </Flex>
            )}
          </Flex>

          <Divider height="2px" />

          <Flex direction="column" background="white" p={8} gap={4}>
            <h3>
              Select a {deliveryMode === "delivery" ? "delivery" : "pickup"}{" "}
              time
            </h3>
            <Flex direction="column" gap={2} className="timeOptions">
              <Flex
                p="16px"
                className={`option ${isPlanningTime ? "" : "active"}`}
                onClick={() => setIsPlanningTime(false)}>
                <NextAvailableSlot restaurantId={restaurantId} />
              </Flex>
              <Flex
                p="16px"
                className={`option ${isPlanningTime ? "active" : ""}`}
                onClick={() => { setIsPlanningTime(true)}}>              
                <Planification 
                
                  deliveryMode={deliveryMode} 
                />
              </Flex>
            </Flex>
          </Flex>

          <Divider height="2px" />

          <Flex direction="column" background="white" p={8} gap={4}>
            <h3>Personal details</h3>
            <Flex
              direction="row"
              align="center"
              justifyContent="space-between"
              p="16px"
              borderRadius={8}
              border="2px solid #121212">
              <Flex direction="row" align="center" gap={4}>
                <User size={24} />
                <Stack gap={0}>
                  <Box>
                    {formData.customerDetails.firstName}{" "}
                    {formData.customerDetails.lastName}
                  </Box>
                  <Box style={{ fontSize: "14px", color: "var(--grey500)" }}>
                    {formData.customerDetails.phone ? (
                      <span>{formData.customerDetails.phone}</span>
                    ) : (
                      "Please insert a phone number"
                    )}
                  </Box>
                </Stack>
              </Flex>
              <Button
                size="sm"
                className="btn secondary sm"
                onClick={toggleUserDetailsModal}>
                Modify
              </Button>
            </Flex>
          </Flex>
          <Divider height="2px" />

          <Flex direction="column" background="white" p={8} gap={4}>
            <h3>How would you like to pay?</h3>
            <Flex direction="column" className={styles.paymentMethods} gap={2}>
              {shopSettings.paymentMethods &&
              Object.values(shopSettings.paymentMethods).filter(
                (method) => method.activation
              ).length > 0 ? (
                Object.values(shopSettings.paymentMethods)
                  .filter((method) => method.activation)
                  .sort((a, b) => a.order - b.order)
                  .map((method, index) => (
                    <label
                      key={index}
                      htmlFor={`payment-method-${method.name}`}
                      className={styles.option}>
                      <div>
                        <input
                          type="radio"
                          id={`payment-method-${method.name}`}
                          name="paymentMethod"
                          value={method.name}
                          checked={selectedPaymentMethod === method.name}
                          onChange={(e) =>
                            setSelectedPaymentMethod(e.target.value)
                          }
                        />
                        <div className={styles.optionName}>{method.label}</div>
                      </div>
                    </label>
                  ))
              ) : (
                <p>No payment methods available</p>
              )}
            </Flex>
            {selectedPaymentMethod === "online" && (
              <StripePaymentForm
                onStripePayment={handlePayClick}
                stripe={stripe}
                elements={elements}
                onChange={handleCardDetailsChange}
              />
            )}
            {errorMessage && (
              <Box color="red" fontSize="sm">
                {errorMessage}
              </Box>
            )}
          </Flex>
        </Flex>

        <Flex direction="column" style={{ flex: "1" }}>
          <Flex direction="column" className={styles.checkoutSticky}>
            <div className={styles.section}>
              <div className={styles.cartContent}>
                <Flex direction="row" justifyContent="space-between">
                  <h3>Order summary</h3>
                  <Link onClick={clearCart}>Clear cart</Link>
                </Flex>
                <CartContent />
              </div>
            </div>
            <Box p={{ desktop: "0", mobile: "0 16px 16px" }}>
              <Button
                width="100%"
                as="button"
                size="lg"
                type="button"
                isLoading={isSubmittingPayment}
                isDisabled={
                  (selectedPaymentMethod === "online" && !stripeFieldsComplete) ||
                  checkoutDisabled ||
                  !isDeliveryZoneValid ||
                  errorMessage ||
                  !nextAvailableSlot ||
                  (deliveryMode === "delivery" && !isAddressSelected)
                }              
                onClick={handlePayClick}
                className="primary">
                Place order<span>{totalWithTip()}€</span>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </main>
  );
};

export default Checkout;
