import { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { db } from "../../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth } from "../../firebaseConfig"; // Assurez-vous d'importer votre configuration Firebase
import {
  Box,
  Flex,
  Stack,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";

const LoginPage = () => {
  
  

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // Ajout d'un état de chargement
  
  useEffect(() => {
    const prevPath = sessionStorage.getItem('prevPath') || '/';
    if (isAuthenticated) {
      navigate(prevPath, { replace: true });
      sessionStorage.removeItem('prevPath');
    }
  }, [isAuthenticated, navigate]);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);


  const handleLoginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await createUserInFirestore(user);
      const prevPath = sessionStorage.getItem('prevPath') || '/';
      navigate(prevPath, { replace: true });
      sessionStorage.removeItem('prevPath');
    } catch (error) {
      alert("Login with Google failed: " + error.message);
    }
  };
  const handleLoginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await createUserInFirestore(user);
      navigate('/', { replace: true }); // ou votre logique de redirection
    } catch (error) {
      alert("Login with Facebook failed: " + error.message);
    }
};

  const createUserInFirestore = async (user) => {
    const userRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      await setDoc(userRef, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        role: 'customer',
      });
    } else {
    }
  };





  
  const handleLoginWithEmail = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Commence le chargement
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const prevPath = sessionStorage.getItem('prevPath') || '/';
      navigate(prevPath || '/', { replace: true });
      sessionStorage.removeItem('prevPath');
      setIsLoading(false); // Arrête le chargement après une tentative réussie
    } catch (error) {
      setIsLoading(false); // Arrête le chargement en cas d'échec
      if (error.code === "auth/user-not-found") {
        try {
          await createUserWithEmailAndPassword(auth, email, password);
          const prevPath = sessionStorage.getItem('prevPath') || '/';
          navigate(prevPath, { replace: true });
          sessionStorage.removeItem('prevPath');
        } catch (signupError) {
          alert("Signup failed: " + signupError.message);
        }
      } else {
        alert("Login failed: " + error.message);
      }
    }
  };

  

  return (
    <>
      <Flex
        width="100%"
        height={"calc(100vh - 96.55px - 51.5px)"}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Flex
          direction="column"
          maxW="360px"
          w={"100%"}
          p={6}
          mx="auto"
          gap={4}>
          <h4>Indiquez votre adresse e-mail</h4>
          <form onSubmit={handleLoginWithEmail}>
            <Stack gap={2}>
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <InputGroup size="lg">
                <Input
                  variant="filled"
                  focusBorderColor="#121212"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type={show ? "text" : "password"}
                  placeholder="Password"
                />
                <InputRightElement>
                  <Button size="xs" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                size="lg"
                bg="var(--brand)"
                color="white"
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
                >
                Continue
              </Button>
            </Stack>
            <Box position="relative" padding="6">
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                or
              </AbsoluteCenter>
            </Box>
            <Stack>
              <Button size="lg" onClick={handleLoginWithGoogle}>
                Login with Google
              </Button>
              <Button size="lg" onClick={handleLoginWithFacebook} isDisabled>Login with Facebook</Button>
            </Stack>
          </form>
        </Flex>
        
      </Flex>
    </>
  );
};

export default LoginPage;
