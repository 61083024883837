import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Flex } from "@chakra-ui/react";


function StripePaymentForm({ onStripePayment, onChange }) {
  const CARD_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    },
    hidePostalCode: true // Cette option cache le champ du code postal.
  };
    return (
    <Flex direction="column" className='stripeOption'>
      <CardElement onChange={onChange} options={CARD_OPTIONS} />
    </Flex>
  );
}

export default StripePaymentForm;
