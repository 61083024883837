import React, { useState, useEffect, useRef } from "react";
import useAllergenes from "../../hooks/useAllergenes";
import useTranslations from "../../frontend/hooks/useTranslations";
import Modal from "react-modal";
import { useShopContext } from "../context/ShopContext";
import { CirclePlus, CircleMinus } from 'lucide-react';
import { Box, Button} from "@chakra-ui/react";



Modal.setAppElement("#root");

const modalStyles = {
  content: {
    height: "100%",
    width: "100%",
    maxWidth: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
    width: "100%",
    height: "100%",
  },
};

const ShopItemModal = ({
  restaurantId,
  item,
  isOpen,
  onClose,
  category,
  styles,
  selectedLanguage,
  defaultLanguage,
  quantity,
  setQuantity,
  itemNote,
  setItemNote,
  handleAddToCart,
  selectedVariation,
  setSelectedVariation,
  subTotal,
  setSubTotal,
  selectedExtras,
  setSelectedExtras,
  formatPrice,
  calculateSubTotal,
  isScrolled,
  setIsScrolled,
}) => {
  const { extrasGroups } = useShopContext(restaurantId);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener("resize", handleResize);

    // Appel initial au chargement du composant
    handleResize();

    // Nettoyage de l'effet
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileContentStyles = {
    ...modalStyles.content,
    alignItems: isMobile ? "flex-end" : "center",
  };

  const allergenes = useAllergenes();
  const { translations } = useTranslations();
  
  const applicableExtrasGroups = extrasGroups.filter(
    (group) =>
      group.applicableToOptions.includes(item.id) ||
      group.applicableToOptions.includes(category.id)
  );

  useEffect(() => {
    if (
      item.type === "variable" &&
      item.variations.length > 0 &&
      !selectedVariation
    ) {
      const firstVariation = item.variations[0];
      setSelectedVariation(firstVariation);
    }
  }, [item, selectedVariation]);

  useEffect(() => {
    if (
      item.type === "variable" &&
      item.variations.length > 0 &&
      !selectedVariation
    ) {
      setSelectedVariation(item.variations[0]);
    }

    // Utilisation d'une fonction pour recalculer subTotal à chaque mise à jour
    setSubTotal(calculateSubTotal());
  }, [quantity, selectedVariation, selectedExtras, item]);

  const incrementQuantity = () => {
    setQuantity((q) => q + 1);
  };
  const decrementQuantity = () => {
    setQuantity((q) => (q > 1 ? q - 1 : 1)); // Empêchez la quantité d'être inférieure à 1
  };

  const itemAllergenes = item.allergenes.map(
    (allergeneName) =>
      allergenes.find((allergene) => allergene.name_en === allergeneName) || {
        name: "ask waiter",
        name_en: "ask waiter",
      }
  );

  const handleVariationChange = (variation) => {
    setSelectedVariation(variation);
  };

  const handleExtraChange = (event, extra, groupId) => {
    const { checked, type } = event.target;
    const isRadio = type === "radio";

    if (checked) {
      if (isRadio) {
        // Pour les boutons radio, remplacez toutes les sélections précédentes dans ce groupe par la nouvelle sélection
        setSelectedExtras((prevExtras) =>
          prevExtras
            .filter(
              (e) =>
                !applicableExtrasGroups
                  .find((group) => group.id === groupId)
                  .items.some((item) => item.name === e.name)
            ) 
            .concat(extra)
        ); 
      } else {
        setSelectedExtras((prevExtras) => [...prevExtras, extra]);
      }
    } else {
      if (!isRadio) {
        setSelectedExtras((prevExtras) =>
          prevExtras.filter((e) => e.name !== extra.name)
        );
      }
    }
  };

  const modalInnerRef = useRef(null);
  const modalHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(modalInnerRef.current.scrollTop > 15);
    };

    const modalContentElement = modalInnerRef.current;
    if (modalContentElement) {
      modalContentElement.addEventListener("scroll", handleScroll);

      return () =>
        modalContentElement.removeEventListener("scroll", handleScroll);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      style={{ content: mobileContentStyles, overlay: modalStyles.overlay }}>
      <div className={`${styles.modalContent} ${styles.shop}`}>
        <div ref={modalInnerRef} className={styles.modalInner}>
          <div
            ref={modalHeaderRef}
            className={`${styles.modalHeader} ${
              isScrolled ? styles.scrolled : ""
            }`}>
            <span className={styles.close} onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className={styles.itemName}>
              <h3
                style={{
                  color:
                    !item.photo || isScrolled
                      ? "var(--restaurantColor)"
                      : "white",
                }}>
                {item[`name_${selectedLanguage}`] ||
                  item[`name_${defaultLanguage}`]}
              </h3>
            </div>
          </div>

          {item.photo && (
            <div className={styles.itemImage}>
              <img
                src={item.photo}
                alt={
                  item[`name_${selectedLanguage}`] ||
                  item[`name_${defaultLanguage}`]
                }
              />
            </div>
          )}

          <div className={styles.itemInfo}>
            {item[`description_${selectedLanguage}`] && (
              <>
                <div className={styles.description}>
                  <h6>Description</h6>
                  <p>
                    {item[`description_${selectedLanguage}`] ||
                      item[`description_${defaultLanguage}`]}
                  </p>
                </div>
                <div className={styles.separator}></div>
              </>
            )}
            {item.tags && item.tags.length > 0 && (
              <>
                <div className={styles.tags}>
                  <>
                    {item[`note_${defaultLanguage}`] && (
                      <div className={styles.note}>
                        {item[`note_${selectedLanguage}`] ||
                          item[`note_${defaultLanguage}`]}
                      </div>
                    )}

                    {item.tags.map((tag, index) => (
                      <span key={index} className={styles.tag}>
                        {tag.label}
                      </span>
                    ))}
                  </>
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {!itemAllergenes.some(
              (allergene) => allergene.name === "ask waiter"
            ) && (
              <>
                <div className={styles.allergenes}>
                  <h6>{translations.allergens[selectedLanguage]}</h6>
                  {itemAllergenes.length > 0 ? (
                    <div className={styles.allergenesList}>
                      {itemAllergenes.map((allergene) => (
                        <div key={allergene.id} className={styles.allergene}>
                          <img
                            src={allergene.colorIcon}
                            className={styles.icon}
                            alt={
                              allergene[`name_${selectedLanguage}`] ||
                              allergene.name_en
                            }
                          />
                          <span>
                            {allergene[`name_${selectedLanguage}`] ||
                              allergene.name_en}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>{translations.noAllergens[selectedLanguage]}</p>
                  )}
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {item.type === "variable" && item.variations.length > 0 && (
              <>
                <div className={styles.options}>
                  <h6>
                    Select an option<span>Mandatory</span>
                  </h6>
                  <div className={styles.optionsList}>
                    {item.variations.map((variation, index) => (
                      <label
                        key={index}
                        htmlFor={`variation-${index}`}
                        className={styles.option}>
                        <div>
                          <input
                            type="radio"
                            name="variation"
                            id={`variation-${index}`}
                            value={variation.name}
                            checked={
                              selectedVariation &&
                              selectedVariation.name === variation.name
                            }
                            onChange={() => handleVariationChange(variation)}
                          />
                          <div className={styles.optionName}>
                            {variation.name}
                          </div>
                        </div>
                        <span className={styles.optionPrice}>
                          {variation.price}€
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {applicableExtrasGroups.map((group) => (
              <React.Fragment key={group.id}>
                <div className={styles.options}>
                  <h6>{group.name}</h6>
                  <div className={styles.optionsList}>
                    {group.items.map((extra) => (
                      <label key={extra.name} className={styles.option}>
                        <div>
                          <input
                            type={
                              group.selectionType === "unique"
                                ? "radio"
                                : "checkbox"
                            }
                            name={
                              group.selectionType === "unique"
                                ? group.name
                                : extra.name
                            }
                            checked={selectedExtras.some(
                              (e) => e.name === extra.name
                            )}
                            onChange={(event) =>
                              handleExtraChange(event, extra, group.id)
                            }
                          />
                          <div className={styles.optionName}>{extra.name}</div>
                        </div>
                        <span className={styles.optionPrice}>
                          {extra.price === 0 || extra.price === "0"
                            ? "Free"
                            : `+${formatPrice(extra.price)}€`}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className={styles.separator}></div>
              </React.Fragment>
            ))}

            <div className={styles.orderNote}>
              <h6>Note</h6>
              <textarea
                placeholder="Add a note..."
                value={itemNote}
                onChange={(e) => setItemNote(e.target.value)}></textarea>
            </div>
          </div>
        </div>
        <div className={styles.addToCart}>
          <div className={styles.qty}>
            <CircleMinus size={32} color="var(--restaurantColor)" onClick={decrementQuantity}  /> 
            <span>{quantity}</span>
            <CirclePlus size={32} color="var(--restaurantColor)" onClick={incrementQuantity}  /> 
          </div>
          <Button size="lg" w="100%" bg="var(--restaurantColor)" _hover={{ bg: "var(--restaurantColor)" }} color="white" onClick={handleAddToCart}>
              <Box className={styles.bgHover}></Box> 
              <Box zIndex={2}>Add {quantity} for {subTotal}€</Box>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShopItemModal;
