// ShopContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
  orderBy,
  Timestamp
} from "firebase/firestore";
import { toast } from "react-toastify";
import styles from "../shop.module.css";
import { Helmet } from "react-helmet";
import ShopHeader from "../components/ShopHeader";

const ShopContext = createContext();

export const useShopContext = () => useContext(ShopContext);

export const ShopProvider = ({ children, restaurantId }) => {
  
  const [generalSettings, setGeneralSettings] = useState({ 
    fees: {
      serviceFee: '',
    },
    vat: '',
  });
  
  const serviceFeeTTC = generalSettings.fees.serviceFee * (1 + generalSettings.vat / 100);
  
  const [settings, setSettings] = useState({
    language: [{ value: "", label: "" }],
    secondaryLanguages: [],
    brandColor: "",
    backgroundColor: "",
    textColor: "",
    logo: {
      menu: "",
      shop: "",
    },
    backgroundImage: "",
    menuUrl: "",
    shopSettings: {
      takeAway: {
        activation: "false",
        minimumOrderAmount: 0,
      },
      delivery: {
        activation: "false",
        minimumOrderAmount: 0,
        zone: [],
        deliveryFee: "",
      },
      contact: {
        shopManagerEmail: '',
        phoneNumber:'',
        address: '',
        companyName: '',
        vat:'',
        rcs: '',
      },
      paymentMethods: {
        till: {
          id: "till",
          activation: false,
          label: "Pay upon pickup",
          order: 0
        },
        online: {
          id: "online",
          label: "Pay online by credit card",
          activation: false,
          PublicLive: "",
          SecretLive: "",
          PublicTest: "",
          SecretTest: "",
          order: 1
        },
      },
      fees: {
        commissionFee: "",
      },
    },
  });

  const deliveryFee = parseFloat(settings.shopSettings.delivery.deliveryFee);

  const [stripeKeys, setStripeKeys] = useState({
    PublicLive: "",
    SecretLive: "",
    PublicTest: "",
    SecretTest: "",
  });
  

  const defaultLanguage = settings.language.length > 0 ? settings.language[0].value : "fr";
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [extrasGroups, setExtrasGroups] = useState([]);

  const [frontendTags, setFrontendTags] = useState([]);
  let initialPrice = 0;
  let initialVariation = null;
  const [quantity, setQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(initialPrice);
  const [selectedVariation, setSelectedVariation] = useState(initialVariation);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [deliveryMode, setDeliveryMode] = useState("pickup");
  const [nextSlotDate, setNextSlotDate] = useState(null);
  const [nextAvailableSlot, setNextAvailableSlot] = useState("");
  const [scheduledSlot, setScheduledSlot] = useState("");
  const [scheduledSlotDate, setScheduledSlotDate] = useState(null);
  const [isLoading, setIsLoading ] = useState(true);
 


  
  const [tip, setTip] = useState(() => {
    const savedTip = localStorage.getItem("tip");
    return savedTip ? parseFloat(savedTip) : 0;
  });
  
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const validPostalCodes = settings.shopSettings.delivery.zone;
  const [isDeliveryZoneValid, setIsDeliveryZoneValid] = useState(true);

  const [isAddressSelected, setIsAddressSelected] = useState(false);



  const onPlaceSelected = (place) => {
    if (!place || !place.address_components) {
      console.error("No address components found.");
      setIsAddressSelected(false);
      return;
    }
  
    const addressObject = place.address_components.reduce(
      (acc, component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            acc.address = `${component.long_name} ${acc.address}`;
            break;
          }
          case "route": {
            acc.address = `${acc.address} ${component.long_name}`.trim();
            break;
          }
          case "locality":
          case "political": {
            acc.city = component.long_name;
            break;
          }
          case "postal_code": {
            acc.postalCode = component.long_name;
            break;
          }
          default: {
            break;
          }
        }
        return acc;
      },
      { address: "", postalCode: "", city: "" }
    );
  
    addressObject.address = addressObject.address.trim(); // Make sure there are no leading or trailing spaces
  
    const postalCodeIsValid = validPostalCodes.includes(addressObject.postalCode);
    setIsDeliveryZoneValid(postalCodeIsValid);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerDetails: {
        ...prevFormData.customerDetails,
        address: addressObject.address,
        postalCode: addressObject.postalCode,
        city: addressObject.city,
      },
    }));
  
    // Update address selected status based on whether a valid address was found
    const isAddressComplete = addressObject.address.length > 0 && addressObject.city && addressObject.postalCode;
    setIsAddressSelected(isAddressComplete && postalCodeIsValid);
  };
  

  useEffect(() => {
    localStorage.setItem("tip", tip.toString());
  }, [tip]);

  const allLanguages = [
    defaultLanguage,
    ...settings.secondaryLanguages.map((lang) => lang.value),
  ].filter(Boolean);

  const cartId = localStorage.getItem("cartId");
  const [cartItems, setCartItems] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);

  useEffect(() => {
    const fetchMainCategories = async () => {
      if (restaurantId) {
        const querySnapshot = await getDocs(
          collection(db, `restaurants/${restaurantId}/menu`)
        );
        const fetchedCategories = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (category) =>
              !category.id.endsWith("-categories") && category.published
          )
          .sort((a, b) => a.order - b.order);

        const mainCategoriesObject = {};
        fetchedCategories.forEach((category) => {
          mainCategoriesObject[category.id] = category;
        });

        setMainCategories(mainCategoriesObject);
      }
    };
    fetchMainCategories();
  }, [restaurantId]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (!restaurantId) return;

      let allSubCategories = [];
      const mainCategoryIds = Object.keys(mainCategories);

      for (const categoryId of mainCategoryIds) {
        const path = `restaurants/${restaurantId}/menu/${categoryId}-categories/categories`;
        const categoriesQuery = query(collection(db, path), orderBy("order"));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const fetchedCategories = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          mainCategoryId: categoryId,
        }));

        allSubCategories = allSubCategories.concat(fetchedCategories);
      }

      setSubCategories(allSubCategories);
    };

    if (Object.keys(mainCategories).length > 0) {
      fetchSubCategories();
    }
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchAllMenuItems = async () => {
      if (!restaurantId) return;

      let allMenuItems = [];
      for (const categoryId of Object.keys(mainCategories)) {
        const path = `restaurants/${restaurantId}/menu/${categoryId}/items`;
        const menuQuery = collection(db, path);
        const menuSnapshot = await getDocs(menuQuery);
        const fetchedMenuData = menuSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          mainCategoryId: categoryId,
        }));

        allMenuItems = allMenuItems.concat(fetchedMenuData);
      }

      // Triez si nécessaire
      allMenuItems.sort((a, b) => a.order - b.order);

      setIsLoading(false);
      setMenuItems(allMenuItems);
    };

    if (Object.keys(mainCategories).length > 0) {
      fetchAllMenuItems();
    }
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (!restaurantId || Object.keys(mainCategories).length === 0) {
          return;
        }

        let allTags = [];
        for (const categoryId of Object.keys(mainCategories)) {
          const path = `restaurants/${restaurantId}/menu/${categoryId}/tags`;
          const querySnapshot = await getDocs(query(collection(db, path)));
          const tagsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            mainCategoryId: categoryId,
          }));

          allTags = allTags.concat(tagsData);
        }

        setFrontendTags(allTags);
      } catch (error) {
        console.error("Erreur lors de la récupération des tags :", error);
      }
    };

    fetchTags();
  }, [restaurantId, mainCategories]); // Dépendances : restaurantId et mainCategories

  useEffect(() => {
    const fetchExtrasGroups = async () => {
      try {
        if (!restaurantId || Object.keys(mainCategories).length === 0) {
          return;
        }

        let allExtrasGroups = [];
        for (const categoryId of Object.keys(mainCategories)) {
          const path = `restaurants/${restaurantId}/menu/${categoryId}/extras`;
          const querySnapshot = await getDocs(collection(db, path));
          const extrasGroupsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            mainCategoryId: categoryId, // Ajoutez cette ligne si vous avez besoin de savoir à quelle catégorie principale chaque groupe d'extras appartient
          }));

          allExtrasGroups = allExtrasGroups.concat(extrasGroupsData);
        }

        setExtrasGroups(allExtrasGroups);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des groupes d'extras :",
          error
        );
      }
    };

    fetchExtrasGroups();
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      const settingsRef = doc(db, "general/settings");
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setGeneralSettings(data);
      } else {
        console.log("No such document!");
      }
    };
    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      if (restaurantId) {
        const settingsRef = doc(
          db, `restaurants/${restaurantId}/settings`, "config" );
        try {
          const docSnap = await getDoc(settingsRef);
          if (docSnap.exists()) {
            let data = docSnap.data();

            if (
              data.shopSettings &&
              data.shopSettings.paymentMethods &&
              !(data.shopSettings.paymentMethods instanceof Array)
            ) {
              data.shopSettings.paymentMethods = Object.entries(
                data.shopSettings.paymentMethods
              ).map(([key, value]) => ({
                name: key,
                ...value,
              }));
            }

            setSettings(data);
            if (data.language && data.language.length > 0) {
              setSelectedLanguage(data.language[0].value);
            }
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      }
    };
    fetchSettings();
  }, [restaurantId]);

  useEffect(() => {
    const fetchCartItems = async () => {
      const cartId = localStorage.getItem("cartId");
      if (!cartId) return;
      const q = query(
        collection(db, `restaurants/${restaurantId}/cart`),
        where("cartId", "==", cartId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const items = querySnapshot.docs.flatMap(
          (doc) => doc.data().items || []
        );
        setCartItems(items);
      }
    };
    fetchCartItems();
  }, [restaurantId, cartId, cartUpdated]);

  
  const handleLanguageChange = (newLanguageValue) => {
    setSelectedLanguage(newLanguageValue);
  };

  const getOrCreateCartId = () => {
    let cartId = localStorage.getItem("cartId");
    if (!cartId) {
      cartId = Math.random().toString(36).substring(2, 15);
      localStorage.setItem("cartId", cartId);
    }
    return cartId;
  };

  

  const addItemToCart = async (cartItem, allLanguages) => {
    const cartId = getOrCreateCartId();
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    const cartSnap = await getDoc(cartRef);

    if (cartSnap.exists()) {
      let cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex(
        (i) =>
          i.itemId === cartItem.itemId &&
          ((i.variation &&
            cartItem.variation &&
            i.variation.name === cartItem.variation.name) ||
            (!i.variation && !cartItem.variation)) && 
          i.note === cartItem.note && 
          compareExtras(i.extras, cartItem.extras)
      );

      if (itemIndex > -1) {
        cartData.items[itemIndex].quantity += cartItem.quantity;
      } else {
        cartData.items.push(cartItem);
      }
      await updateDoc(cartRef, { items: cartData.items });
    } else {
      await setDoc(cartRef, {
        items: [cartItem],
        cartId: cartId,
      });
    }
    setCartUpdated((prev) => !prev);
  };

  function compareExtras(extras1 = [], extras2 = []) {
    if (extras1.length !== extras2.length) return false;
    const sortedExtras1 = [...extras1].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const sortedExtras2 = [...extras2].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return sortedExtras1.every(
      (extra, index) => extra.name === sortedExtras2[index].name
    );
  }

  const handleDeliveryModeChange = (event) => {
    setDeliveryMode(event.target.value);
    if (event.target.value === "pickup") {
      setFormData({ ...formData });
    }
  };

  const updateItemQuantity = async (itemId, variationName, quantity, note = null, extras = []) => {
    const cartId = localStorage.getItem("cartId");
    if (!cartId) return;
  
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    const cartSnap = await getDoc(cartRef);
  
    if (cartSnap.exists()) {
      let cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex((i) => {
        const isItemIdMatch = i.itemId === itemId;
        const isVariationMatch = variationName ? i.variation && i.variation.name === variationName : !i.variation;
        const isNoteMatch = i.note === note || (i.note === undefined && note === null);
        const isExtrasMatch = extras.length === (i.extras ? i.extras.length : 0) && 
                              extras.every(extra => i.extras.some(e => e.name === extra.name));
  
        return isItemIdMatch && isVariationMatch && isNoteMatch && isExtrasMatch;
      });
  
      if (itemIndex > -1 && quantity > 0) {
        cartData.items[itemIndex].quantity = quantity;
      } else if (itemIndex > -1 && quantity <= 0) {
        cartData.items.splice(itemIndex, 1);
      }
  
      await updateDoc(cartRef, cartData);
      setCartUpdated((prev) => !prev);
    }
  };
  

  const calculateSubTotal = () => {
    let basePrice = 0;

    if (selectedItem.type === "variable" && selectedVariation) {
      basePrice = parseFloat(selectedVariation.price.replace(",", "."));
    } else if (selectedItem.type !== "variable" && selectedItem.price) {
      basePrice = parseFloat(selectedItem.price.replace(",", "."));
    }

    const extrasTotal = selectedExtras.reduce((total, extra) => {
      const extraPrice = parseFloat(extra.price);
      return total + extraPrice;
    }, 0);

    const total = (basePrice + extrasTotal) * quantity;

    return formatPrice(total);
  };

  const calculateTotal = () => {
    const total = cartItems.reduce((acc, item) => {
      const itemTotal =
        parseFloat(item.price.replace(",", ".")) * item.quantity;
      return acc + itemTotal;
    }, 0);
    return total.toFixed(2);
  };

  const totalWithTip = () => {
    const total = calculateTotal();
    const totalNum = parseFloat(total.replace(",", "."));
    const deliveryFeeToAdd = deliveryMode === 'delivery' ? deliveryFee : 0;
    const totalWithTip = (totalNum + tip + serviceFeeTTC + deliveryFeeToAdd).toFixed(2);
    return totalWithTip.replace(".", ",");
};


  const clearCart = async () => {
    const cartId = localStorage.getItem('cartId');
    if (!cartId) {
      console.error("No cartId found in localStorage.");
      return;
    }
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    try {
      await updateDoc(cartRef, { items: [] });
      localStorage.removeItem('cartId'); 
      localStorage.removeItem('tip'); 
      setTip(0);
      setCartItems([]);
    } catch (error) {
      console.error("Error clearing the cart:", error);
    }
  };
  
  const [formData, setFormData] = useState({
    customerDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      postalCode: "",
      city: "",
    },
  });

  const submitOrder = async (orderData, paymentStatus = "unpaid") => {
    
    if (!orderData.scheduledTime) {
      console.error('Erreur: scheduledTime est indéfini.');
      throw new Error('scheduledTime est requis.');
    }

    if (isNaN(new Date(orderData.scheduledTime).getTime())) {
      console.error('La date fournie est invalide:', orderData.scheduledTime);
      throw new Error('Invalid date provided');
    }

    const orderId = Math.floor(10000 + Math.random() * 90000).toString();
    const createdAt = new Date();
        
    const scheduledTime = Timestamp.fromDate(orderData.scheduledTime); 
  
    const orderDataComplete = {
      ...orderData,
      orderId: orderId,
      tip: tip,
      deliveryMode: deliveryMode,
      createdAt: createdAt,
      status: "pending",
      restaurantId: restaurantId,
      restaurantDetails: {
          company: settings.shopSettings.contact.companyName,
          address: settings.shopSettings.contact.address,
          vat: settings.shopSettings.contact.vat,
          logo: settings.logo.shop
      },
      serviceFee: generalSettings.fees.serviceFee,
      deliveryFee: deliveryMode === 'delivery' ? settings.shopSettings.delivery.deliveryFee : 0,
      serviceFeeTTC: serviceFeeTTC,
      paymentStatus: paymentStatus,
      scheduledTime: scheduledTime,
      deliveryNote: orderData.deliveryNote,
    };
  
    const orderRef = doc(db, `restaurants/${restaurantId}/orders`, orderId);
    try {
      await setDoc(orderRef, orderDataComplete);
      await clearCart();
      return orderDataComplete; // Retourner les données complètes de la commande
    } catch (error) {
      console.error("Erreur lors de la création de la commande :", error);
      toast.error("There was an error during checkout. Please retry.");
      throw error; // Lancez l'erreur pour l'attraper dans le gestionnaire de paiement
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      customerDetails: {
        ...prevState.customerDetails,
        [name]: value, // Mise à jour de la clé correspondante dans customerDetails
      },
    }));
  };

  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      console.error("Price is not a valid number in formatPrice");
      return "0,00";
    }
    return numericPrice.toFixed(2).replace(".", ",");
  };

  const isFormValid = () => {
    const hasPaymentMethod = selectedPaymentMethod !== null;
    const areCommonFieldsValid =
      formData.customerDetails.firstName &&
      formData.customerDetails.lastName &&
      formData.customerDetails.email &&
      formData.customerDetails.phone;
    if (deliveryMode === "delivery") {
      return hasPaymentMethod && areCommonFieldsValid;
    }
    return hasPaymentMethod && areCommonFieldsValid;
  };

  const calculateMinimumOrderAmountDifference = () => {
    const totalValue = parseFloat(calculateTotal());
    const minimumOrderAmountValue =
      deliveryMode === "delivery"
        ? parseFloat(settings.shopSettings.delivery.minimumOrderAmount)
        : parseFloat(settings.shopSettings.takeAway.minimumOrderAmount);
    const differenceValue = minimumOrderAmountValue - totalValue;
    return differenceValue > 0 ? differenceValue : 0;
  };

  const total = parseFloat(calculateTotal());
  const minimumOrderAmount = parseFloat(
    settings.shopSettings.minimumOrderAmount
  );
  const difference = minimumOrderAmount - total;

  const isCheckoutDisabledCart = () => {
    const cartIsEmpty = cartItems.length === 0;
    return cartIsEmpty || calculateMinimumOrderAmountDifference() > 0;
  };
  
  const isCheckoutDisabledCheckout = () => {
    const cartIsEmpty = cartItems.length === 0;
    return cartIsEmpty || calculateMinimumOrderAmountDifference() > 0 || !isFormValid();
  };
  

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }


  const loadStripeKeys = async () => {
    if (restaurantId) {
      const docRef = doc(db, `stripeConfigs/${restaurantId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStripeKeys(docSnap.data());
      } else {
        console.log("Aucune configuration Stripe trouvée.");
      }
    }
  };

  

  return (
    <ShopContext.Provider
      value={{
        restaurantId,
        selectedLanguage,
        setSelectedLanguage,
        handleLanguageChange,
        defaultLanguage,
        allLanguages,
        mainCategories,
        setMainCategories,
        subCategories,
        setSubCategories,
        menuItems,
        setMenuItems,
        cartId,
        cartItems,
        setCartItems,
        getOrCreateCartId,
        addItemToCart,
        cartUpdated,
        setCartUpdated,
        updateItemQuantity,
        calculateSubTotal,
        calculateTotal,
        clearCart,
        styles,
        formData,
        setFormData,
        submitOrder,
        handleChange,
        ...generalSettings,
        ...settings,
        serviceFeeTTC,
        deliveryFee,
        selectedVariation,
        setSelectedVariation,
        selectedItem,
        setSelectedItem,
        selectedExtras,
        setSelectedExtras,
        formatPrice,
        subTotal,
        setSubTotal,
        initialPrice,
        initialVariation,
        quantity,
        setQuantity,
        tip,
        setTip,
        totalWithTip,
        frontendTags,
        setFrontendTags,
        extrasGroups,
        setExtrasGroups,
        deliveryMode,
        setDeliveryMode,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        total,
        minimumOrderAmount,
        difference,
        isCheckoutDisabledCart,
        isCheckoutDisabledCheckout,
        isFormValid,
        calculateMinimumOrderAmountDifference,
        handleDeliveryModeChange,
        validPostalCodes,
        isDeliveryZoneValid,
        setIsDeliveryZoneValid,
        stripeKeys, setStripeKeys,
        loadStripeKeys,
        onPlaceSelected,
        nextAvailableSlot, setNextAvailableSlot,
        nextSlotDate, setNextSlotDate,
        scheduledSlot, setScheduledSlot,
        scheduledSlotDate, setScheduledSlotDate,
        isLoading,
        isAddressSelected, setIsAddressSelected,
      }}>
      <Helmet>
        <title>{capitalizeWords(restaurantId)} - Online shop</title>
        <meta name="description" content={"Online shop"} />
      </Helmet>

      <div className={styles.shop}>
        <ShopHeader
          restaurantId={restaurantId}
          logoUrl={settings.logo.shop}
          menuUrl={settings.menuUrl}
          styles={styles}
          selectedLanguage={selectedLanguage}
          secondaryLanguages={settings.secondaryLanguages}
          handleLanguageChange={handleLanguageChange}
        />

        {children}
        
      </div>
    </ShopContext.Provider>
  );
};
