import CartContent from "./CartContent";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useShopContext } from "../context/ShopContext";
import { Box, Flex, Stack, Button, Divider, Link } from "@chakra-ui/react";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    height: "100%",
    width: "100%",
    maxWidth: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
  },
};

const CartModal = ({ restaurantId, isOpen, onClose, styles }) => {
  const { cartItems, calculateTotal, tip, totalWithTip } = useShopContext();

  const navigate = useNavigate();
  const handleCheckout = () => {
    navigate(`/${restaurantId}/shop/checkout`);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      style={customStyles}>
      <div className={`${styles.modalContent} ${styles.shop}`}>
        <div className={styles.modalHeader}>
          <span className={styles.close} onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                fill="white"
              />
            </svg>
          </span>
          <h4>Your bag</h4>
        </div>

        <div className={styles.cartContent}>
          <Flex direction="column" p="24px" gap={8}>
            <CartContent restaurantId={restaurantId} />
            <div className={styles.cartActions}>
              <button
                onClick={handleCheckout}
                className={`${styles.primary} ${
                  cartItems.length === 0 ? styles.inactive : ""
                }`}>
                Checkout{" "}
                {cartItems.length > 0 && <span>{totalWithTip()}€</span>}
              </button>
            </div>{" "}
          </Flex>
        </div>
      </div>
    </Modal>
  );
};

export default CartModal;
