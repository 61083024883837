import React from "react";
import { useInView } from 'react-intersection-observer';

const AnimatedImage = ({ src, alt }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5  // Déclenche l'animation quand 50% de l'élément est visible
  });

  return (
    <div ref={ref} className={`imageAnimation ${inView ? 'startAnimation' : ''}`}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default AnimatedImage;
