import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { useShopContext } from '../../context/ShopContext';
import { Input } from "@chakra-ui/react";
  
const AddressAutoComplete = () => {
  
  const {
    styles,
    isDeliveryZoneValid, 
    onPlaceSelected,
    setIsAddressSelected
  } = useShopContext();
  
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete) => {
    autocomplete.setComponentRestrictions({ country: ["lu"] });
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place);
        setIsAddressSelected(true); 
      } else {
        console.log('No valid address selected.');
        setIsAddressSelected(false);
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };
  
  

  return (
    <>
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
    >
      <Input
    size="lg"
    variant="filled"
    focusBorderColor="#121212"
    type="text"
    placeholder="Enter your address"
    onChange={(e) => {
      if (!e.target.value.trim()) {
        setIsAddressSelected(false);
      }
    }}
  />
    </Autocomplete>
      {!isDeliveryZoneValid && (
        <div
        className={styles.errorMessage}
          style={{ maxWidth: "100%", textAlign: "left" }}>
          Apologies, but we are unable to deliver to this address.
          Please provide an alternative address or opt for pickup.
        </div>
      )}
      </>
  );
};

export default AddressAutoComplete;
