import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "react-router-dom";

const Header = ({ restaurantId, logo, styles, defaultLanguage, secondaryLanguages, selectedLanguage, handleLanguageChange }) => {
  
  
return (
    <header>
      <div className={styles.container}>
        <Link to={`/${restaurantId}/menu`} className={styles.logo} id="#logo" title="Back to home">
          {logo && <img src={logo} alt="Logo" />}
        </Link>
      
      <div className={styles.right}>

         <LanguageSwitcher 
          selectedLanguage={selectedLanguage}
          defaultLanguage={defaultLanguage}
          secondaryLanguages={secondaryLanguages}
          onLanguageChange={handleLanguageChange} 
          styles={styles}
        />
        


        </div>
      </div>
    </header>
  );
};

export default Header;
