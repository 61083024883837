import { extendTheme } from "@chakra-ui/react";

const useBreakpoints = () => {
  
  const breakpoints = {
    "2xl": "1920px",
    xl: "1550px",
    large: "1190px",
    desktop: "960px",
    mobile: "0px"
  };

  const theme = extendTheme({ breakpoints });

  return {
    breakpoints,
    theme,
  };
};

export default useBreakpoints;
