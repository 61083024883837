import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box } from "@chakra-ui/react";

const ShopSubCategoryMenu = ({ subCategories, itemsByCategory, styles, defaultLanguage, selectedLanguage }) => {
  const [activeId, setActiveId] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
            scrollToActiveLink(entry.target.id);
          }
        });
      },
      { rootMargin: '-80px 0px -90%' }
    );

    subCategories.forEach(subCategory => {
      const element = document.getElementById(subCategory.id);
      if (element) observer.observe(element);
    });

    return () => {
      subCategories.forEach(subCategory => {
        const element = document.getElementById(subCategory.id);
        if (element) observer.unobserve(element);
      });
    };
  }, [subCategories]);

  const scrollToActiveLink = (activeLinkId) => {
    const activeLink = document.querySelector(`.cat-link[href="#${activeLinkId}"]`);
    if (activeLink && menuRef.current) {
      const linkOffsetLeft = activeLink.offsetLeft;
      const linkWidth = activeLink.offsetWidth;
      const menuScrollLeft = menuRef.current.scrollLeft;
      const menuWidth = menuRef.current.offsetWidth;

      const linkLeftEdge = linkOffsetLeft - menuScrollLeft;
      const linkRightEdge = linkLeftEdge + linkWidth;

      if (linkLeftEdge < 0 || linkRightEdge > menuWidth) {
        menuRef.current.scrollLeft = linkOffsetLeft - (menuWidth / 2) + (linkWidth / 2);
      }
    }
  };

  const handleLinkClick = (e, id) => {
    e.preventDefault();
    const anchor = document.getElementById(id);
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const visibleCategories = subCategories.filter(subCategory => {
    const items = itemsByCategory[subCategory.id];
    return items && items.some(item => item.display && item.display.includes('shop'));
  });

  return (
    <nav className={styles.subCategoriesNav}>
      <div className={styles.container}>
        
        <Flex direction="row" ref={menuRef} alignItems="baseline" justifyContent="flex-end">
          {visibleCategories.map((subCategory) => (
              <a
              key={subCategory.id}
              className={`${styles.subCategoriesLink} ${activeId === subCategory.id ? `${styles.active}` : ''}`}
              href={`#${subCategory.id}`}
              onClick={(e) => handleLinkClick(e, subCategory.id)}
            >
              {subCategory[`name_${selectedLanguage}`] || subCategory[`name_${defaultLanguage}`]}
            </a>
          ))}
        </Flex>
        
      </div>
    </nav>
  );
};

export default ShopSubCategoryMenu;
