import { useState, useRef, useEffect } from "react";
import {
  Switch,
  Box,
  Grid,
  Flex,
  Link,
  Image,
  Heading,
  Text,
  Button,
  Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel
} from "@chakra-ui/react";
import MultiColorBg from "./img/bg23.webp";
import logo from "./img/guddenApp.svg";
import mockup from "./img/guddenappmockup1.webp";
import iphones from "./img/iphones.webp";
import {
  Calendar,
  Utensils,
  Store,
  Calculator,
  Mail, 
  Phone
} from "lucide-react";
import ContactForm from "./components/ContactForm";
import ImageSlider from "./components/ImageSlider";
import OptimizedImage from "./components/OptimizedImage";
import { Helmet } from 'react-helmet';
import LoadingScreen from './components/LoadingScreen'; // Assurez-vous que le chemin est correct

const Home = () => {
 
  const [isYearly, setIsYearly] = useState(false); // false pour monthly, true pour yearly
  const toggleSwitch = () => setIsYearly(!isYearly);

 
  const contactRef = useRef(null); // Créer une référence à la section contact

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulez un délai de chargement de 2 secondes

    return () => clearTimeout(loadTimeout);
  }, []);

  return (
    <>
    <Helmet>
        <title>Gudden App - Manage Your Restaurant Effortlessly In One Place</title>
        <meta name="description" content="Gudden.app helps you manage all aspects of your restaurant in one place. From menus to reservations to orders, streamline your operations effortlessly." />
        <meta name="keywords" content="restaurant management, online menus, reservations, order management, gudden app, digital menus, print menus, online ordering" />
        <meta name="author" content="Good Robot Sarls" />
        <meta property="og:title" content="Gudden App - Manage Your Restaurant Effortlessly In One Place" />
        <meta property="og:description" content="Gudden.app helps you manage all aspects of your restaurant in one place. From menus to reservations to orders, streamline your operations effortlessly." />
        <meta property="og:image" content={mockup} />
        <meta property="og:url" content="https://www.gudden.app" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Gudden App - Manage Your Restaurant Effortlessly In One Place" />
        <meta name="twitter:description" content="Gudden.app helps you manage all aspects of your restaurant in one place. From menus to reservations to orders, streamline your operations effortlessly." />
        <meta name="twitter:image" content={mockup} />
      </Helmet>

      {isLoading && <LoadingScreen />}
      
      {!isLoading && (
      <>
      <Flex as="header" alignItems="center" p={8} zIndex={10} id="introduction">
        <Flex maxWidth={"1320px"} justifyContent={"space-between"} w={"100%"}>
          <Link href="/"><Image w="62px" h="62px" src={logo} /></Link>
          <Flex gap={8} alignItems={"center"}>
            <Link
              href="https://admin.gudden.app"
              color="var(--grey600)"
              target="_blank"
              fontWeight="600">
              Login
            </Link>
            <Button
                onClick={scrollToContact}  
                aria-label="Scroll to contact section"
                size="md"
                color="white"
                bg="var(--brand)"
                _hover={{ bg: 'var(--brand600)' }}
                borderRadius={"12px"}>
                    Contact us
            </Button>
          </Flex>
        </Flex>
      </Flex>


      <Flex as="main" justifyContent={"center"} direction={"column"} alignItems={"center"}>
      
      
      <Flex
        as="section"
        pt={48}
        pb={48}
        pl={{ desktop: 16, mobile: 4 }}
        pr={{ desktop: 16, mobile: 4 }}
        mt="-122px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        backgroundImage={MultiColorBg}
        backgroundAttachment="scroll"
        className="bg">
        
        <Box
          w="100%"
          h="100%"
          position={"absolute"}
          top={0}
          left={0}
          bg="hsla(0, 0%, 100%, .6);"></Box>

        <Flex
          direction={{ desktop: "row", mobile: "column" }}
          p={8}
          maxW={"1320px"}>
          <Flex direction="column"  w={{ desktop: "54%", mobile: "100%" }} gap={8}>
            <Box position="absolute" top="-13%" left="-11%">
              <svg
                width="74"
                height="72"
                viewBox="0 0 74 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M49.9123 51.1167C49.9123 51.1167 49.6554 51.3188 49.4818 51.4234C48.44 52.051 47.3375 51.9109 46.9985 51.0743C41.8583 38.3939 34.0481 27.6057 23.857 18.9141C23.2231 18.3735 23.3563 17.1816 24.2447 16.3389C25.0879 15.4998 26.339 15.2191 27.0216 15.8014C37.5053 24.7424 45.476 35.836 50.7768 48.8218C51.0599 49.5265 50.6793 50.4654 49.9158 51.1619L49.9123 51.1167Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.83"
                  strokeMiterlimit="10"
                />
                <path
                  d="M64.6306 34.9169C64.6306 34.9169 64.4118 35.0251 64.3214 35.0322C63.1678 35.396 62.0475 35.0302 61.8406 34.1377L56.1221 8.9616C55.9151 8.06916 56.6988 7.05299 57.8524 6.68919C59.006 6.32538 60.1263 6.69125 60.3333 7.58369L66.0518 32.7598C66.2516 33.5619 65.5963 34.4771 64.6306 34.9169V34.9169Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.85"
                  strokeMiterlimit="10"
                />
                <path
                  d="M33.2893 61.4756C33.2893 61.4756 33.2132 61.6634 33.13 61.7609C32.5342 62.8531 31.5233 63.2965 30.8062 62.8533L10.876 48.8879C10.2005 48.396 10.1006 47.1316 10.6512 46.043C11.247 44.9508 12.2579 44.5074 12.975 44.9505L32.9539 58.9575C33.5807 59.4078 33.7115 60.488 33.2893 61.4756Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.82"
                  strokeMiterlimit="10"
                />
              </svg>
            </Box>
            <Box position="absolute" top="43%" right="-22%">
              <svg
                width="152"
                height="147"
                viewBox="0 0 152 147"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_103_79)">
                  <path
                    d="M131.526 146.566C130.752 146.455 130.04 145.915 129.807 145.094C124.931 129.564 109.214 117.772 91.9594 114.552C88.6108 125.052 80.3703 134.194 68.707 134.183C64.631 134.167 61.0096 132.816 58.8434 130.493C55.5418 126.913 55.6662 121.156 59.191 117.068C61.6656 114.186 65.509 112.157 71.0034 110.845C76.817 109.448 82.8697 109.136 88.7748 109.852C88.8548 109.295 88.9838 108.701 89.0208 108.137C91.1738 92.5226 84.825 76.2565 72.4773 65.7353C65.9474 60.2048 57.8087 56.4122 49.3319 54.6708C47.2129 58.7414 44.6827 62.6218 41.8334 66.2813C38.6585 70.3754 34.1391 75.2827 27.5151 77.132C21.2103 78.8958 12.6353 76.9216 9.73969 70.5128C8.5864 67.8538 8.6118 64.9264 9.84645 62.1288C11.898 57.6111 17.2336 53.4333 23.4771 51.4857C30.8686 49.1779 38.9164 48.7141 46.9453 49.9099C53.1675 36.8039 55.4123 22.0771 53.1267 7.7502C52.9428 6.58641 53.7534 5.52157 54.8769 5.3328C56.0432 5.1502 57.1111 5.95964 57.3011 7.08056C59.6479 21.8975 57.4644 37.1142 51.2605 50.7041C60.0504 52.7092 68.4652 56.7164 75.2283 62.4553C88.6746 73.8778 95.6 91.6266 93.244 108.656C93.1579 109.256 93.0779 109.813 92.9918 110.413C111.64 114.008 128.609 126.855 133.859 143.751C134.227 144.853 133.57 146.071 132.471 146.395C132.152 146.48 131.839 146.523 131.538 146.48L131.526 146.566ZM87.6196 113.93C82.3897 113.398 77.0797 113.73 71.9905 114.968C67.4047 116.06 64.2797 117.667 62.4313 119.852C60.6383 121.957 59.962 125.447 61.9749 127.617C63.3433 129.082 65.8046 129.96 68.7021 129.939C77.9099 129.948 84.6829 122.476 87.6626 113.936L87.6196 113.93ZM44.9184 53.95C38.068 53.0982 31.1068 53.6305 24.7282 55.6024C19.6819 57.1531 15.255 60.4988 13.7132 63.9086C12.9393 65.6349 12.9327 67.2088 13.6197 68.8385C15.4847 72.9559 21.8132 74.3889 26.3376 73.1133C31.7339 71.5691 35.6393 67.2736 38.4333 63.6936C40.8467 60.6277 42.9838 57.3471 44.8693 53.9867L44.9184 53.95Z"
                    fill="var(--brand)"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_103_79">
                    <rect
                      width="135"
                      height="129"
                      fill="white"
                      transform="translate(18.3232) rotate(8.16601)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Box>
            <Heading
              as="h1"
              color="#343f52"
              letterSpacing="-0.2px"
              fontSize="50px"
              fontWeight="500"
              lineHeight="1"
              textTransform={"initial"}>
              Manage all aspects of your restaurant in{" "}
              <span style={{ color: "var(--brand)", fontWeight: 600 }}>
                one place.
              </span>
            </Heading>
            <Text maxWidth="520px" fontSize="24px" color="#60607b">
            From menus to reservations to orders—streamline all your operations effortlessly with Gudden.app
            </Text>
            <Flex gap={4}>
              <Button
                size="lg"
                color="white"
                bg="var(--brand)"
                _hover={{ bg: 'var(--brand600)' }}
                borderRadius={"12px"}
                onClick={scrollToContact}  
                aria-label="Scroll to contact section"
                >
                Contact us
              </Button>
            </Flex>
          </Flex>
          <Flex flex={1} mt={8} ml={12} mb={"-20rem"} zIndex={2}>
            <figure>
              <OptimizedImage src={mockup} alt="Gudden.app mockup showcasing the restaurant management features" />
            </figure>
          </Flex>
        </Flex>
        <Box position={"absolute"} bottom={0} left={0} right={0}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
            <g fill="white">
              <polygon points="1440 100 0 100 0 85 1440 0 1440 100"></polygon>
            </g>
          </svg>
        </Box>
      </Flex>

      <Flex
        as="section"
        id="modules"
        direction="row"
        pt={48}
        pb={0}
        pl={{ desktop: 16, mobile: 4 }}
        pr={{ desktop: 16, mobile: 4 }}
        justifyContent="center"
        w="100%">
        <Flex maxWidth="1320px" justifyContent="center">
          <Flex
            direction="column"
            w={{desktop: "70%", mobile: "90%"}}
            gap={16}
            justifyContent="center"
            alignItems={"center"}>
            <Flex
              direction="column"
              w="100%"
              gap={4}
              justifyContent="center"
              alignItems={"center"}>
              <Heading
                as="h6"
                color="#aab0bc"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase">
                App modules
              </Heading>
              <Heading
                as="h3"
                textAlign={"center"}
                color="#343F52"
                fontWeight="500"
                fontSize="2.6em"
                lineHeight={1}>
                Gudden.app makes your restaurant management{" "}
                <span style={{ color: "var(--brand)", fontWeight: 600 }}>
                  stress-free
                </span>{" "}
                for you to have the perfect control.
              </Heading>
            </Flex>
            <Grid
              templateColumns={{ desktop: "repeat(4, 1fr)", mobile: "repeat(2, 1fr)"}}
              gap={16}
              alignItems={"start"}>
              
              <Flex
                direction="column"
                gap={4}
                justifyContent={"center"}
                alignItems={"center"}
                className="module">
                <Flex bg="#e1f6f0" color="#e1f6f0" p={8} borderRadius="12px" className="moduleBtn">
                  <Utensils size={30} color="#343f52" />
                </Flex>
                <Heading as="h6" fontSize="20px" fontWeight={"500"}>
                  Menus
                </Heading>
                
              </Flex>
              
             
              
              

              
              <Flex
                direction="column"
                gap={4}
                justifyContent={"center"}
                alignItems={"center"}
                className="module">
                <Flex bg="#feece9" color="#feece9" p={8} borderRadius="12px" className="moduleBtn">
                  <Store size={30} color="#343f52" />
                </Flex>
                <Heading as="h6" fontSize="20px" fontWeight={"500"}>
                  Online shop
                </Heading>
              </Flex>

              <Flex
                direction="column"
                gap={4}
                justifyContent={"center"}
                alignItems={"center"}
                className="module">
                <Flex bg="#fef3e4" color="#fef3e4" p={8} borderRadius="12px" className="moduleBtn">
                  <Calendar size={30} color="#343f52" />
                </Flex>
                <Heading as="h6" fontSize="20px" fontWeight={"500"}>
                  Reservations
                </Heading>
              </Flex>


              <Flex
                direction="column"
                gap={4}
                justifyContent={"center"}
                alignItems={"center"}
                className="module">
                <Flex bg="#e0e9fa" color="#e0e9fa" p={8} borderRadius="12px" className="moduleBtn">
                  <Calculator size={30} color="#343f52" />
                </Flex>
                <Heading as="h6" fontSize="20px" fontWeight={"500"}>
                  Costs & stocks
                </Heading>
              </Flex>
              

              
              
            </Grid>

          </Flex>

        </Flex>

      </Flex>


      <Flex
        as="section"
        id="features"
        direction="row"
        pt={48}
        pb="790px"
        pl={{ desktop: 16, mobile: 4 }}
        pr={{ desktop: 16, mobile: 4 }}
        justifyContent="center"
        w="100%">
        <Flex maxWidth="1320px" w="100%" justifyContent="center">
          <Flex
            direction="column"
            w="100%"
            maxWidth={"100vw"}
            gap={16}
            justifyContent="center"
            alignItems={"center"}>
            
            <Flex
              direction="column"
              maxWidth={{desktop: "490px", mobile: "90%"}}
              gap={4}
              justifyContent="center"
              alignItems={"center"}>
              <Heading
                as="h6"
                color="#aab0bc"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase">
                What's included?
              </Heading>
              <Heading
                as="h3"
                textAlign={"center"}
                color="#343F52"
                fontWeight="500"
                fontSize="2.6em"
                lineHeight={1}>
                 Here are {""}
                <span style={{ color: "var(--brand)", fontWeight: 600 }}>
                a few benefits
                </span>{" "}
                if you choose gudden.app
              </Heading>
            </Flex>
            

            
            <Flex direction={{ desktop: "row", mobile: "column" }} gap={16} maxWidth={"100vw"} w={"100%"}>

                <Flex flex="0 0 auto" w={{ desktop: "50%", mobile: "100%"}}>
                  <Box w={"100%"}>
                    <ImageSlider />
                  </Box>
                </Flex>


                <Flex w={{ desktop: "50%", mobile: "100%"}} direction="column" gap={16}>
                
                <Flex gap={8} alignItems={"start"}>
                <Flex bg="var(--brand100)" borderRadius={{ desktop: "12px", mobile: "8px"}} p={{ desktop: 6, mobile: 4}}>
                        <Heading fontSize={{ desktop: "20px", mobile: "16px"}} color="var(--brand)" fontWeight={"500"}>01</Heading>
                    </Flex>
                    <Flex direction="column" gap={2}>
                    <Heading as="h3" fontWeight="600" fontSize="20px" >
                    100% cloud-based solution
                    </Heading>
                    <Text color="#60697B" fontSize={"17px"}>
                    gudden.app is a fully cloud-based SaaS application, ensuring your data is securely stored and accessible from any device. Experience the freedom and flexibility of accessing and editing your information anywhere, anytime.
                    </Text>
                    </Flex>
                </Flex>
                <Flex gap={8} alignItems={"start"}>
                <Flex bg="var(--brand100)" borderRadius={{ desktop: "12px", mobile: "8px"}} p={{ desktop: 6, mobile: 4}}>
                  <Heading fontSize={{ desktop: "20px", mobile: "16px"}} color="var(--brand)" fontWeight={"500"}>02</Heading>
                    </Flex>
                    <Flex direction="column" gap={2}>
                    <Heading as="h3" fontWeight="600" fontSize="20px" >
                    Multilingual in-app translation
                    </Heading>
                    <Text color="#60697B" fontSize={"17px"}>
                    Embrace global accessibility with gudden.app, featuring menu translations in six languages: English, French, Luxembourgish, Spanish, Portuguese, and Italian. Seamlessly switch languages to enhance user experience.
                    </Text>
                    </Flex>
                </Flex>
                <Flex gap={8} alignItems={"start"}>
                <Flex bg="var(--brand100)" borderRadius={{ desktop: "12px", mobile: "8px"}} p={{ desktop: 6, mobile: 4}}>
                  <Heading fontSize={{ desktop: "20px", mobile: "16px"}} color="var(--brand)" fontWeight={"500"}>03</Heading>
                    </Flex>
                    <Flex direction="column" gap={2}>
                    <Heading as="h3" fontWeight="600" fontSize="20px" >
                    Unlimited user access
                    </Heading>
                    <Text color="#60697B" fontSize={"17px"}>
                    Expand your team's capabilities without restrictions. gudden.app allows you to provide access to an unlimited number of users, facilitating collaboration and efficiency across your organization.</Text>
                    </Flex>
                </Flex>
                <Flex gap={8} alignItems={"start"}>
                <Flex bg="var(--brand100)" borderRadius={{ desktop: "12px", mobile: "8px"}} p={{ desktop: 6, mobile: 4}}>
                  <Heading fontSize={{ desktop: "20px", mobile: "16px"}} color="var(--brand)" fontWeight={"500"}>04</Heading>
                    </Flex>
                    <Flex direction="column" gap={2}>
                    <Heading as="h3" fontWeight="600" fontSize="20px" >
                    White labeling
                    </Heading>
                    <Text color="#60697B" fontSize={"17px"}>
                    Our white labeling features enable you to present an online shop and menu that fully embody your unique brand identity, making it appear as if it's your own website. Elevate your customer's online experience with a platform that seamlessly integrates with your brand aesthetics.                   </Text>
                    </Flex>
                </Flex>
                <Flex gap={8} alignItems={"start"}>
                <Flex bg="var(--brand100)" borderRadius={{ desktop: "12px", mobile: "8px"}} p={{ desktop: 6, mobile: 4}}>
                  <Heading fontSize={{ desktop: "20px", mobile: "16px"}} color="var(--brand)" fontWeight={"500"}>05</Heading>
                    </Flex>
                    <Flex direction="column" gap={2}>
                    <Heading as="h3" fontWeight="600" fontSize="20px" >
                      Print your menu                    
                    </Heading>
                    <Text color="#60697B" fontSize={"17px"}>
                    If you still prefer offering a physical menu to your customers, Gudden.app makes it simple. With just a few clicks, you can print your menu directly from your web browser in a beautifully designed layout. Easy as 1-2-3! </Text>
                    </Flex>
                </Flex>
                

                </Flex>
            


            </Flex>

          </Flex>

        </Flex>

      </Flex>









      <Flex 
        as="section" 
        direction="column"
        pt={0} 
        pb={24} 
        pl={{ desktop: 16, mobile: 8 }}
        pr={{ desktop: 16, mobile: 8 }}
        w="100%"
        className="bg"
        backgroundImage={MultiColorBg}
        backgroundAttachment="scroll"
        backgroundSize={"cover"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={16}>
    
        <Box
          w="100%"
          h="100%"
          position={"absolute"}
          top={0}
          left={0}
          bg="hsla(0, 0%, 100%, .6);"></Box>


          <Flex
             mt="-660px" 
             id="pricing" 
              pt={24}
              direction="column"
              maxWidth="960px"
              gap={4}
              justifyContent="center"
              alignItems={"center"}>
              <Heading
                as="h6"
                color="#aab0bc"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase">
                Pricing
              </Heading>
              <Heading
                as="h3"
                textAlign={"center"}
                color="#343F52"
                fontWeight="500"
                fontSize="2.6em"
                lineHeight={1}
                maxWidth="420px">
                 Choose the package {""}
                <span style={{ color: "var(--brand)", fontWeight: 600 }}>
                adapted
                </span>{" "}
                to your needs.
              </Heading>
              <Text color={"#60697B"} fontSize={"17px"} textAlign={"center"}>
              Explore our range of pricing packages tailored to meet the unique needs of your restaurant. Whether you're just starting or seeking to upgrade your operations, we offer both standard and customizable module combinations to align with your goals and budget. Find your ideal solution and start optimizing your restaurant management today.
              </Text>

              </Flex>


        <Flex justifyContent="center" alignItems="center" gap={4}>
          <Text fontSize="18px" fontWeight={"500"}>Monthly</Text>
          <Switch isChecked={isYearly} onChange={toggleSwitch} size="lg" sx={{ '.chakra-switch__track[data-checked]:not([data-theme])': { backgroundColor: 'var(--brand)', }, }}/>
          <Text fontSize="18px" fontWeight={"500"}>Yearly <Text textTransform={"uppercase"} position={"absolute"} left="120%" top="50%" transform={"translateY(-50%)"}  fontSize="11px" fontWeight="500" bg="#ECFCCB" color="#365314" w="fit-content" p="4px 8px" borderRadius={"4px"}>Discount</Text></Text>
        </Flex>
            
    
    <Flex w="100%" maxWidth={"1320px"} gap={32} direction={"column"} justifyContent={"center"} alignItems={"center"}  >  
      
      <Grid
              templateColumns={{ desktop: "repeat(3, 1fr)", mobile: "repeat(1, 1fr)"}}
              gap={12}
              w="100%"
             >

        <Flex bg={"rgb(254,254,254)"} boxShadow={"0 .25rem 1.75rem rgba(30,34,40,.07)"} p={24} borderRadius={16} direction={"column"} justifyContent={"space-between"} gap={16} w={"100%"} overflow="hidden">
            <Flex direction={"column"} gap={8}>
          
            <h3>Starter</h3>
            
            {isYearly &&
                <Flex direction="column" className="priceAnimation" gap={8}>
                    <Flex alignItems="flex-end" gap={2}>
                        <Text as="s" color="#60697b" fontSize="lg">708€</Text>
                        <h2>649€</h2>
                        <Text color="#60697b" whiteSpace={"nowrap"}>/ year</Text>
                    </Flex>
                </Flex>
            }

            {!isYearly &&
                <Flex className="priceAnimation">
                    <Flex alignItems="flex-end" gap={2}>
                        <h2>59€</h2>
                        <Text color="#60697b"  whiteSpace={"nowrap"}>/ month</Text>
                    </Flex>
                </Flex>
            }

            
          
            <Flex direction={"column"} gap={4}>
                <Text fontWeight="500" color={"#60697b"}>Modules</Text>
                <Flex  direction={"column"} gap={2}>
                
                
                
                <Flex gap={2} alignItems={"center"}>
                    <Flex bg="#e1f6f0" p={2} borderRadius="6px">
                    <Utensils size={16} color="#343f52" />
                    </Flex>
                    <Heading as="h6" fontSize="16px" fontWeight={"500"}>
                        Menus                    
                    </Heading>
                </Flex>

                </Flex>


            </Flex>
            
           


            </Flex>
      
            {isYearly &&       
                <Box fontSize="13px" fontWeight="500" bg="#ECFCCB" color="#365314" w="fit-content" p="4px 48px"  
                position="absolute" top='-10px' left='-10px' transform='rotate(-45deg) translate(-30%, 0%)' transformOrigin={'center'}>
                    1 month free
                </Box>
            }
            
            <Button size="lg" 
            bg='var(--brand)'                 
            _hover={{ bg: 'var(--brand600)' }}
            color="white" 
            onClick={scrollToContact}
            aria-label="Scroll to contact section"
            >
              Contact us
            </Button>
            

        </Flex>   

        <Flex bg={"rgb(254,254,254)"} boxShadow={"0 .25rem 1.75rem rgba(30,34,40,.07)"} p={24} borderRadius={16} direction={"column"} justifyContent={"space-between"} gap={16} overflow="hidden">
            <Flex direction={"column"} gap={8}>
            <h3>Essentials</h3>
         

            {isYearly &&
                <Flex direction="column" className="priceAnimation" gap={8}>
                    <Flex alignItems="flex-end" gap={2}>
                        <Text as="s" color="#60697b" fontSize="lg">948€</Text>
                        <h2>869€</h2>
                        <Text color="#60697b"  whiteSpace={"nowrap"}>/ year</Text>
                    </Flex>
                </Flex>
            }

            {!isYearly &&
                <Flex className="priceAnimation">
                    <Flex alignItems="flex-end" gap={2}>
                        <h2>79€</h2>
                        <Text color="#60697b"  whiteSpace={"nowrap"}>/ month</Text>
                    </Flex>
                </Flex>
            }



            
            
            <Flex direction={"column"} gap={4}>
                <Text fontWeight="500" color={"#60697b"}>Starter modules + </Text>
                
              
                
                <Flex gap={2} alignItems={"center"}>
                    <Flex bg="#feece9" p={2} borderRadius="6px">
                    <Store size={16} color="#343f52" />
                    </Flex>
                    <Heading as="h6" fontSize="16px" fontWeight={"500"}>
                        Online shop                    
                    </Heading>
                
                </Flex>

               
            
             


            </Flex>
            
            </Flex>
            {isYearly &&       
                <Box fontSize="13px" fontWeight="500" bg="#ECFCCB" color="#365314" w="fit-content" p="4px 48px"  
                position="absolute" top='-10px' left='-10px' transform='rotate(-45deg) translate(-30%, 0%)' transformOrigin={'center'}>
                    1 month free
                </Box>
            }
            <Button size="lg" bg='var(--brand)' color="white" _hover={{ bg: 'var(--brand600)' }} onClick={scrollToContact} aria-label="Scroll to contact section">Contact us</Button>

        </Flex>   
 

        <Flex bg={"rgb(254,254,254)"} boxShadow={"0 .25rem 1.75rem rgba(30,34,40,.07)"} p={24} borderRadius={16} direction={"column"} justifyContent={"space-between"} gap={16} overflow="hidden">
            <Flex direction={"column"} gap={8}>
            <h3>Options</h3>
           

            {isYearly &&
                <Flex direction="column" className="priceAnimation" gap={8}>
                    <Flex alignItems="flex-end" gap={2}>
                        <Text as="s" color="#60697b" fontSize="lg">120€</Text>
                        <h2>110€</h2>
                        <Text color="#60697b"  whiteSpace={"nowrap"}>/ year / module</Text>
                    </Flex>
                </Flex>
            }

            {!isYearly &&
                <Flex className="priceAnimation">
                    <Flex alignItems="flex-end" gap={2}>
                        <h2>10€</h2>
                        <Text color="#60697b"  whiteSpace={"nowrap"}>/ month / module</Text>
                    </Flex>
                </Flex>
            }

            
            <Flex direction={"column"} gap={4}>
            
                <Flex gap={2} alignItems={"center"}>
                    <Flex bg="#fef3e4" p={2} borderRadius="6px">
                    <Calendar size={16} color="#343f52" />
                    </Flex>
                    <Heading as="h6" fontSize="16px" fontWeight={"500"}>
                    Reservations
                    </Heading>
                </Flex>
                
                <Flex gap={2} alignItems={"center"}>
                    <Flex bg="#e0e9fa" p={2} borderRadius="6px">
                    <Calculator size={16} color="#343f52" />
                    </Flex>
                    <Heading as="h6" fontSize="16px" fontWeight={"500"}>
                        Costs & stocks                   
                    </Heading>
                </Flex>
                
                
            </Flex>
            
            </Flex>
            {isYearly &&       
                <Box fontSize="13px" fontWeight="500" bg="#ECFCCB" color="#365314" w="fit-content" p="4px 48px"  
                position="absolute" top='-10px' left='-10px' transform='rotate(-45deg) translate(-30%, 0%)' transformOrigin={'center'}>
                    1 month free
                </Box>
            }
            <Button size="lg" bg='var(--brand)' _hover={{ bg: 'var(--brand600)' }} color="white" onClick={scrollToContact} aria-label="Scroll to contact section">Contact us</Button>
        </Flex>   

         </Grid> 

        

         
         <Flex direction="column" gap={16} w='100%' justifyContent={"center"} alignItems={"center"}>
         <Flex
              direction="column"
              w={{ desktop: "640px", mobile:"90%" }}
              gap={4}
              justifyContent="center"
              alignItems={"center"}>
              <Heading
                as="h6"
                color="#aab0bc"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase">
                FAQ
              </Heading>
              <Heading
                as="h3"
                textAlign={"center"}
                color="#343F52"
                fontWeight="500"
                fontSize="2.6em"
                lineHeight={1}>
                If you don't see an answer to your question, you can <Link color={"var(--brand)"} onClick={scrollToContact}>send us an email</Link> from our contact form.
              </Heading>
            </Flex>

            <Flex  direction={{ desktop: "row", mobile: "column" }}  gap='24px' w="100%">


<Flex direction={"column"} flex={1} >

                <Accordion allowMultiple w="100%">
                <AccordionItem bg="white" borderRadius={8} border="none" mb={4}>
  <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important">
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>Are there any setup fees or hidden costs?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
      <Text color="#60697B" fontSize={"17px"}>
      No, gudden.app does not charge any setup fees or have hidden costs. Our pricing is transparent, and all costs are clearly outlined in your subscription plan. You pay only for the package you choose, with no unexpected fees, ensuring you can budget effectively and utilize our services without surprises.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  
  <AccordionItem bg="white" borderRadius={8} border="none" mb={4}>
  <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important" >
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>How does gudden.app ensure the security of transactions?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
        <Text color="#60697B" fontSize={"17px"}>
        gudden.app integrates with Stripe, a leading payment processing platform, to handle all transactions on your online shop securely. By utilizing Stripe, we ensure that every payment is processed reliably without storing any credit card data on our servers. This partnership allows us to offer a safe, efficient checkout experience, safeguarding your customers' financial information while maintaining PCI compliance.
      </Text>
    </AccordionPanel>
  </AccordionItem>

  <AccordionItem bg="white" borderRadius={8} border="none">
  <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important" >
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>Is training provided for new users of gudden.app?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
        <Text color="#60697B" fontSize={"17px"} >
        Yes, you receive a 30-minute demo and training session to help you familiarize yourself with our platform's features and functionalities. Additionally, we provide comprehensive documentation that covers all aspects of the application. This resource is designed to assist you in navigating and utilizing the platform effectively, ensuring you have the support you need to manage your restaurant’s online operations efficiently.      </Text>
    </AccordionPanel>
  </AccordionItem>

  

 
</Accordion>
                
                </Flex>
                <Flex direction={"column"} flex={1} >
                <Accordion allowMultiple>
 
                <AccordionItem bg="white" borderRadius={8} border="none" mb={4}>
                <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important">
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>What if I choose to cancel my subscription?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
      <Text color="#60697B" fontSize={"17px"}>
        Every subscription to gudden.app, whether billed monthly or annually, is subject to a renewable one-year contract. To cancel your subscription, please notify us at least one month before the contract year ends. This advance notice ensures a smooth transition and prevents automatic renewal for another year.        </Text>
    </AccordionPanel>
  </AccordionItem>

  
  <AccordionItem bg="white" borderRadius={8} border="none" mb={4}>
  <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important">
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>Does gudden.app handle delivery services?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
      <Text color="#60697B" fontSize={"17px"}>
      No, gudden.app does not provide delivery services directly. Our platform is designed to help you manage all your orders in-house. You will need to have your own delivery personnel to handle the delivery of orders. However, you have the flexibility to activate or deactivate the take-away and delivery options as needed, allowing you to tailor our service to fit your restaurant’s operational style and customer demand.
      </Text>
    </AccordionPanel>
  </AccordionItem>


  <AccordionItem bg="white" borderRadius={8} border="none">
  <AccordionButton  color="var(--grey600)" justifyContent={"space-between"} p="16px 24px !important">
          <Text fontSize="18px" fontWeight="500" whiteSpace={"wrap"} textAlign={"left"}>Can gudden.app integrate with other software?</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={8} pl={8} pr={8} pt={4}>
      <Text color="#60697B" fontSize={"17px"}>
      Currently, gudden.app does not support integration with other software systems used in restaurants. However, we are actively working on developing this capability. In the future, we plan to offer integrations that will allow gudden.app to seamlessly connect with various POS systems and other management tools, enhancing the efficiency and flexibility of your restaurant operations. Stay tuned for updates on this feature!      </Text>
    </AccordionPanel>
  </AccordionItem>


</Accordion>
                
                </Flex>
                </Flex>

            </Flex>
        
            
        
        </Flex>
      </Flex>




      <Flex
        as="section"
        id="contact"
        ref={contactRef}        
        pt={48}
        pb={96}
        pl={{ desktop: 16, mobile: 8 }}
        pr={{ desktop: 16, mobile: 8 }}
        justifyContent="center"
        w="100%">

        <Box 
          position={"absolute"}
          top="7%"
          right={"20%"}
        >
        <svg width="110" height="114" viewBox="0 0 110 114" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_106_53)">
<path d="M0.181543 100.709C0.248261 100.116 0.643188 99.5611 1.26361 99.3644C12.9903 95.288 21.6167 83.0326 23.6721 69.8036C15.5898 67.4937 8.42974 61.4228 8.16849 52.5308C8.08653 49.4229 9.0326 46.6309 10.7536 44.9256C13.4064 42.3258 17.798 42.2875 20.9959 44.88C23.2504 46.7 24.8866 49.5831 26.0135 53.7415C27.2129 58.1414 27.5913 62.7486 27.1819 67.267C27.6086 67.3151 28.0644 67.3998 28.4948 67.4149C40.4489 68.6951 52.7029 63.4786 60.4383 53.8216C64.5035 48.7154 67.2066 42.423 68.3381 35.9201C65.1858 34.3989 62.1689 32.5597 59.3131 30.4721C56.1184 28.1464 52.2726 24.8145 50.7095 19.8072C49.219 15.0414 50.5257 8.45837 55.3446 6.10259C57.3451 5.16183 59.5774 5.11346 61.7388 5.99001C65.2305 7.44956 68.5389 11.4206 70.1682 16.1355C72.0986 21.7171 72.6384 27.8418 71.9125 33.9905C82.0481 38.4309 93.3274 39.8016 104.197 37.7277C105.08 37.5606 105.911 38.1539 106.08 39.006C106.247 39.891 105.654 40.7239 104.804 40.8946C93.5623 43.0266 81.9109 41.714 71.4068 37.2987C70.0816 44.0463 67.2212 50.5542 63.0025 55.843C54.6054 66.3584 41.2344 72.0487 28.1972 70.6466C27.7377 70.5948 27.311 70.5468 26.8515 70.495C24.5425 84.7949 15.1411 98.0292 2.38155 102.422C1.54938 102.728 0.605527 102.256 0.333456 101.425C0.260939 101.184 0.22124 100.946 0.247186 100.716L0.181543 100.709ZM24.0465 66.4807C24.3309 62.4812 23.9552 58.4407 22.8934 54.5895C21.9549 51.1186 20.6569 48.7734 18.9487 47.4148C17.3024 46.0965 14.6259 45.6617 13.0182 47.2465C11.933 48.3237 11.3204 50.2204 11.404 52.4288C11.6102 59.4489 17.4628 64.4396 24.0428 66.5136L24.0465 66.4807ZM68.7855 32.5388C69.2764 27.2965 68.7096 22.0017 67.0586 17.1845C65.7597 13.3732 63.1066 10.0756 60.4714 8.97913C59.1374 8.42902 57.9373 8.46041 56.7108 9.02191C53.615 10.539 52.6689 15.3968 53.746 18.8166C55.0481 22.8948 58.4133 25.7728 61.2072 27.8201C63.6004 29.589 66.1509 31.1425 68.7564 32.5022L68.7855 32.5388Z" fill="var(--brand)"/>
</g>
<defs>
<clipPath id="clip0_106_53">
<rect width="102.968" height="98.3916" fill="white" transform="translate(109.301 11.0151) rotate(96.4278)"/>
</clipPath>
</defs>
</svg>
        </Box>
      
        <Flex         
            direction={{ desktop: "row", mobile: "column" }}        
            maxWidth="1320px" justifyContent="center" gap="96px" w="100%">

            <Flex direction="column" gap={8} flex={1}>
              <Heading
                as="h6"
                color="#aab0bc"
                fontSize="16px"
                fontWeight="600"
                textTransform="uppercase">
                Contact
              </Heading>
              <Heading
                as="h3"
                color="#343F52"
                fontWeight="500"
                fontSize="2.6em"
                lineHeight={1}
                maxWidth={"380px"}>
                Reach out for a <span style={{ color: "var(--brand)", fontWeight: 600 }}>demo</span> or more information
              </Heading>
              <Text color="#60697B" fontSize={"17px"} maxWidth={"460px"}>
                Interested in seeing how gudden.app can transform your restaurant's operations? Fill out the form to schedule a free demo or to speak with one of our experts. Whether you have questions or need assistance, we're here to help you make the most of your digital experience. Connect with us today to explore the possibilities!
              </Text>
              

              <Flex direction={"column"} gap={8} mt={16}>
                <Flex gap={4} alignItems={"center"}>
                  <Mail size={24} color="var(--brand)"/>
                    <h5>info@gudden.app</h5>
                </Flex>
                <Flex gap={4} alignItems={"center"}>
                  <Phone size={24} color="var(--brand)"/>
                    <h5>+352 26 36 04 53</h5>
                </Flex>


              </Flex>


            </Flex>
            
            <Flex direction="column" gap={8} flex={1} mt={{ desktop: 48, mobile: 0 }} p={{ desktop: 8, mobile: 4 }} borderRadius={8} boxShadow={"0 .25rem 1.75rem rgba(30,34,40,.07)"}>
            <ContactForm />
            </Flex>

        </Flex>
    </Flex>



    <Flex
        as="section"
        pt={48}
        pb={24}
        pl={{ desktop: 16, mobile: 4 }}
        pr={{ desktop: 16, mobile:4 }}
        mt="-122px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        backgroundImage={MultiColorBg}
        backgroundAttachment="scroll"
        className="bg">
        
        <Box
          w="100%"
          h="100%"
          position={"absolute"}
          top={0}
          left={0}
          bg="hsla(0, 0%, 100%, .6);"></Box>

        <Flex
          direction={{ desktop: "row", mobile: "column-reverse" }}
          p={8}
          maxW={"1320px"}>
          <Flex direction="column"  w={{ desktop: "54%", mobile: "100%" }} gap={8}>
            <Box position="absolute" top="-13%" left="-11%">
              <svg
                width="74"
                height="72"
                viewBox="0 0 74 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M49.9123 51.1167C49.9123 51.1167 49.6554 51.3188 49.4818 51.4234C48.44 52.051 47.3375 51.9109 46.9985 51.0743C41.8583 38.3939 34.0481 27.6057 23.857 18.9141C23.2231 18.3735 23.3563 17.1816 24.2447 16.3389C25.0879 15.4998 26.339 15.2191 27.0216 15.8014C37.5053 24.7424 45.476 35.836 50.7768 48.8218C51.0599 49.5265 50.6793 50.4654 49.9158 51.1619L49.9123 51.1167Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.83"
                  strokeMiterlimit="10"
                />
                <path
                  d="M64.6306 34.9169C64.6306 34.9169 64.4118 35.0251 64.3214 35.0322C63.1678 35.396 62.0475 35.0302 61.8406 34.1377L56.1221 8.9616C55.9151 8.06916 56.6988 7.05299 57.8524 6.68919C59.006 6.32538 60.1263 6.69125 60.3333 7.58369L66.0518 32.7598C66.2516 33.5619 65.5963 34.4771 64.6306 34.9169V34.9169Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.85"
                  strokeMiterlimit="10"
                />
                <path
                  d="M33.2893 61.4756C33.2893 61.4756 33.2132 61.6634 33.13 61.7609C32.5342 62.8531 31.5233 63.2965 30.8062 62.8533L10.876 48.8879C10.2005 48.396 10.1006 47.1316 10.6512 46.043C11.247 44.9508 12.2579 44.5074 12.975 44.9505L32.9539 58.9575C33.5807 59.4078 33.7115 60.488 33.2893 61.4756Z"
                  fill="var(--brand)"
                  stroke="var(--brand)"
                  strokeWidth="0.82"
                  strokeMiterlimit="10"
                />
              </svg>
            </Box>
            <Box position="absolute" top="43%" right="-5%">
              <svg
                width="152"
                height="147"
                viewBox="0 0 152 147"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_103_79)">
                  <path
                    d="M131.526 146.566C130.752 146.455 130.04 145.915 129.807 145.094C124.931 129.564 109.214 117.772 91.9594 114.552C88.6108 125.052 80.3703 134.194 68.707 134.183C64.631 134.167 61.0096 132.816 58.8434 130.493C55.5418 126.913 55.6662 121.156 59.191 117.068C61.6656 114.186 65.509 112.157 71.0034 110.845C76.817 109.448 82.8697 109.136 88.7748 109.852C88.8548 109.295 88.9838 108.701 89.0208 108.137C91.1738 92.5226 84.825 76.2565 72.4773 65.7353C65.9474 60.2048 57.8087 56.4122 49.3319 54.6708C47.2129 58.7414 44.6827 62.6218 41.8334 66.2813C38.6585 70.3754 34.1391 75.2827 27.5151 77.132C21.2103 78.8958 12.6353 76.9216 9.73969 70.5128C8.5864 67.8538 8.6118 64.9264 9.84645 62.1288C11.898 57.6111 17.2336 53.4333 23.4771 51.4857C30.8686 49.1779 38.9164 48.7141 46.9453 49.9099C53.1675 36.8039 55.4123 22.0771 53.1267 7.7502C52.9428 6.58641 53.7534 5.52157 54.8769 5.3328C56.0432 5.1502 57.1111 5.95964 57.3011 7.08056C59.6479 21.8975 57.4644 37.1142 51.2605 50.7041C60.0504 52.7092 68.4652 56.7164 75.2283 62.4553C88.6746 73.8778 95.6 91.6266 93.244 108.656C93.1579 109.256 93.0779 109.813 92.9918 110.413C111.64 114.008 128.609 126.855 133.859 143.751C134.227 144.853 133.57 146.071 132.471 146.395C132.152 146.48 131.839 146.523 131.538 146.48L131.526 146.566ZM87.6196 113.93C82.3897 113.398 77.0797 113.73 71.9905 114.968C67.4047 116.06 64.2797 117.667 62.4313 119.852C60.6383 121.957 59.962 125.447 61.9749 127.617C63.3433 129.082 65.8046 129.96 68.7021 129.939C77.9099 129.948 84.6829 122.476 87.6626 113.936L87.6196 113.93ZM44.9184 53.95C38.068 53.0982 31.1068 53.6305 24.7282 55.6024C19.6819 57.1531 15.255 60.4988 13.7132 63.9086C12.9393 65.6349 12.9327 67.2088 13.6197 68.8385C15.4847 72.9559 21.8132 74.3889 26.3376 73.1133C31.7339 71.5691 35.6393 67.2736 38.4333 63.6936C40.8467 60.6277 42.9838 57.3471 44.8693 53.9867L44.9184 53.95Z"
                    fill="var(--brand)"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_103_79">
                    <rect
                      width="135"
                      height="129"
                      fill="white"
                      transform="translate(18.3232) rotate(8.16601)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Box>
            <Heading
              as="h1"
              color="#343f52"
              letterSpacing="-0.2px"
              fontSize="50px"
              fontWeight="500"
              lineHeight="1"
              textTransform={"initial"}>
              Manage all aspects of your restaurant in{" "}
              <span style={{ color: "var(--brand)", fontWeight: 600 }}>
                one place.
              </span>
            </Heading>
            <Text maxWidth="520px" fontSize="24px" color="#60607b">
              From menus to reservations to orders—streamline all your operations effortlessly with Gudden.app
            </Text>
            <Flex gap={4}>
              <Button
                size="lg"
                color="white"
                bg="var(--brand)"
                _hover={{ bg: 'var(--brand600)' }}
                borderRadius={"12px"}
                onClick={scrollToContact}
                aria-label="Scroll to contact section"  
                >
                Contact us
              </Button>
            </Flex>
          </Flex>
          <Flex flex={1} ml={12} mt={{ desktop: "-20rem", mobile: "-25rem"}}  zIndex={2}>
            <figure>
              <OptimizedImage src={iphones} alt="Gudden.app mockup showcasing the restaurant management features"/>
            </figure>
          </Flex>
        </Flex>
        <Box position={"absolute"} top={0} left={0} right={0}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100"><g fill="white"><polygon points="1440 100 0 15 0 0 1440 0 1440 100"></polygon></g></svg>
        </Box>
      </Flex>


   

      </Flex>
      
      <Flex as="footer" justifyContent="center" alignItems="center" 
      pt={24}
      pb={16}
      pl={16}
      pr={16}
      w={"100%"}>
      
      <Flex maxWidth={"1320px"} justifyContent={"space-between"} w={"100%"} alignItems={"flex-start"} gap={24} direction={{ desktop: "row", mobile: "column"}}>
          
          
          <Flex direction={"column"} gap={8} flex={1}>
            <Link href="/"><Image w="42px" h="42px" src={logo} /></Link>
            <Text color='#60607b' fontSize={"16px"}>© 2024 Good Robot Sarls.<br/>All rights reserved.</Text>
            <Link href="https://www.goodrobot.lu" target="_blank" color="var(--brand)" fontWeight="600">www.goodrobot.lu</Link>
          </Flex>
          <Flex direction={"column"} gap={8} flex={1}>
              <h5>Get in touch</h5>  
              <Flex direction={"column"} gap={4}>
                <Flex gap={4} alignItems={"center"}>
                  <Mail size={20} color="var(--brand)"/>
                  <Text color={"#60697B"}>info@gudden.app</Text>
                </Flex>
                <Flex gap={4} alignItems={"center"}>
                  <Phone size={20} color="var(--brand)"/>
                  <Text color={"#60697B"}>+352 26 36 04 53</Text>
                </Flex>
              </Flex>          
          </Flex>
          <Flex direction={"column"} gap={8} flex={1}>
              <h5>Quick navigation</h5>
              <Flex direction={"column"} gap={2}>
                  <Link href="#modules" color='#60607b' fontSize={"16px"}>Modules</Link>
                  <Link href="#features" color='#60607b' fontSize={"16px"}>Features</Link>
                  <Link href="#pricing" color='#60607b' fontSize={"16px"}>Pricing</Link>
                  <Link href="#contact" color='#60607b' fontSize={"16px"}>Contact</Link>
              </Flex>
          </Flex>
          <Flex direction={"column"} gap={8} flex={1}>        
          <h5>Legal documents</h5>
            <Flex direction={"column"} gap={2}>
                  <Link color='#60607b' fontSize={"16px"}>Data protection</Link>
                  <Link color='#60607b' fontSize={"16px"}>Impressum</Link>
              </Flex>
        </Flex>
        </Flex>
        
      </Flex>
      
      </>)}
    </>
  );
};

export default Home;
