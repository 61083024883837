import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import macbook1 from "../img/macbook1.webp";
import macbook2 from "../img/macbook2.webp";
import macbook3 from "../img/macbook3.webp";
import { Box } from "@chakra-ui/react";
import AnimatedImage from './AnimatedImage';
const sliderImages = [macbook1, macbook2, macbook3];

function ImageSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    slideToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false
  };

  return (
      <Slider {...settings} className="imageSlider">
        {sliderImages.map((img, index) => (
          <Box as='figure' key={index} w={"100%"}>
            <AnimatedImage src={img} alt={`Slide ${index}`} style={{ width: "100%", display: "block" }} />
          </Box>
        ))}
      </Slider>
  );
}

export default ImageSlider;
