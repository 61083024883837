import { useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';

const AuthStateHandler = ({ restaurantId }) => {
  const { currentUser } = useAuth();
  const prevUserRef = useRef();

  useEffect(() => {
    // Stockez la valeur actuelle dans une ref à chaque rendu.
    prevUserRef.current = currentUser;
  });

  const clearCart = async () => {
    localStorage.removeItem('cartId');
    localStorage.removeItem('tip'); 
  };

  useEffect(() => {
    // Obtenez la valeur précédente de currentUser.
    const prevUser = prevUserRef.current;

    // Exécutez clearCart si l'état d'authentification change.
    if (prevUser !== currentUser) {
      clearCart();
    }
  }, [currentUser]);

  return null;
};

export default AuthStateHandler;
