import React from 'react';
import LazyLoad from 'react-lazyload';
import AnimatedImage from './AnimatedImage';

const OptimizedImage = ({ src, alt }) => {
  return (
    <LazyLoad height={200} offset={100} once>
      <AnimatedImage src={src} alt={alt} loading="lazy" style={{ width: '100%', height: 'auto' }} />
    </LazyLoad>
  );
};

export default OptimizedImage;
