import React, { useState } from "react";
import Sidebar from "./shop/components/Sidebar";
import { Flex, Box, Spacer, Container, Image, Link } from '@chakra-ui/react'

function GuddenLayout({ children }) {

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);
      
    return (
      <>
      <header style={{ padding: '0' }}>
      <Container maxW='1920px'>
        <Flex p='6'>
        <Box className="logo">
            <Link href='/'><Image src='/images/Good-Robot-Logo.png' alt='logo' width="60px" /></Link>
        </Box>
        <Spacer />
        <Box className="sidebarToggle" onClick={toggleDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12ZM2 18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18Z"
                fill="black"
              />
            </svg>
          </Box>
      </Flex>
      </Container>
      </header>
      {children}

      <Sidebar
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
      />
      </>
    );
  }
export default GuddenLayout; 