import React, { useState, useEffect, useRef } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';


const SubCategoryMenu = ({ subCategories, itemsByCategory, styles, defaultLanguage, selectedLanguage }) => {
  
  const [activeId, setActiveId] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const navRef = useRef(null);
  const sentinelRef = useRef(null);
  const observerRef = useRef(null);
  const visibilityApiRef = useRef(null);

  useEffect(() => {
    const sentinelObserver = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { root: null, rootMargin: '0px', threshold: [0, 1.0] }
    );
  
    const sentinel = sentinelRef.current;
    if (sentinel) {
      sentinelObserver.observe(sentinel);
    }
  
    return () => {
      if (sentinel) {
        sentinelObserver.unobserve(sentinel);
      }
    };
  }, []);
  
  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (!isManualScroll) {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setActiveId(entry.target.id);
              centerActiveItem(entry.target.id);
            }
          });
        }
      },
      { rootMargin: '-80px 0px -90%' }
    );
  
    subCategories.forEach(subCategory => {
      const element = document.getElementById(subCategory.id);
      if (element) observerRef.current.observe(element);
    });
  
    return () => {
      subCategories.forEach(subCategory => {
        const element = document.getElementById(subCategory.id);
        if (element) observerRef.current.unobserve(element);
      });
    };
  }, [subCategories, isManualScroll]);
  


  const centerActiveItem = (activeId) => {
    if (visibilityApiRef.current) {
      const { getItemById, scrollToItem } = visibilityApiRef.current;
      const item = getItemById(activeId);
      if (item) {
        scrollToItem(item, 'smooth', 'center');
      } else {
      }
    }
  };
  
  const handleLinkClick = (e, id) => {
    e.preventDefault();
  
    const anchor = document.getElementById(id);
    if (anchor) {
      const topOffset = anchor.getBoundingClientRect().top + window.pageYOffset - 80;
      window.scrollTo({ top: topOffset, behavior: "smooth", block: "center" });
    }
  
    setActiveId(id);
  };
  
  
  const visibleCategories = subCategories.filter(subCategory => {
    const items = itemsByCategory[subCategory.id];
      const availableItems = items && items.filter(item => item.availability === true);
    return availableItems && availableItems.length > 0;
  });
  

  

  
  return (
    <>
      <div ref={sentinelRef} style={{ height: '1px', width: '100%' }}></div>
      
      <nav ref={navRef} className={`${styles.subCategoriesNav} ${isSticky ? `${styles.sticked}` : ''}`}>
        <div className={styles.container}>
        <ScrollMenu
          apiRef={visibilityApiRef}
          onInit={(api) => visibilityApiRef.current = api}
          onUpdate={(api) => visibilityApiRef.current = api}
          scrollContainerClassName={styles.subCategoriesLinks}
          wrapperClassName={styles.subCategoriesLinksWrapper}
        >

          {visibleCategories.map((subCategory) => (
            <div key={subCategory.id} itemId={subCategory.id}>
              <a
                className={`${styles.subCategoriesLink} ${activeId === subCategory.id ? `${styles.active}` : ''}`}
                href={`#${subCategory.id}`}
                onClick={(e) => handleLinkClick(e, subCategory.id)}
              >
                {subCategory[`name_${selectedLanguage}`] || subCategory[`name_${defaultLanguage.value}`]}
              </a>
            </div>
          ))}

          </ScrollMenu>
        </div>
      </nav>
    </>
  );
};

export default SubCategoryMenu;
