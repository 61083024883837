

const MenuSwitcher = ({ onCategoryChange, currentMainCategory, mainCategories, styles, defaultLanguage, selectedLanguage }) => {

  const handleChangeCategory = (categoryId) => {
    const categoryObject = mainCategories[categoryId];
    onCategoryChange(categoryObject);
    setTimeout(() => window.scrollTo(0, 0), 100); 
  };
  
  return (
    <ul className={styles.mainCategoriesSwitcher}>
    {Object.keys(mainCategories).map(categoryId => (
      <li 
        key={categoryId} 
        className={`${styles.mainCategoryLink} ${currentMainCategory && currentMainCategory.id === categoryId ? `${styles.active}`: ''}`}
        onClick={() => handleChangeCategory(categoryId)}
      >
        <button className={styles.btn && styles.mainCategoryButton}>
          <span>{mainCategories[categoryId].menuIcon}</span> 
          <span>{mainCategories[categoryId][`name_${selectedLanguage}`] || mainCategories[categoryId][`name_${defaultLanguage.value}`]}</span>
        </button>

      </li>
    ))}
  </ul>
  );
};

export default MenuSwitcher;
