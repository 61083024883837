import React, { useState } from "react";
import { useShopContext } from "../../context/ShopContext";
import { Flex, Text } from "@chakra-ui/react";
import useSlots from "../../../hooks/useSlots";
import { CalendarClock } from "lucide-react";
import SelectSlot from "./SelectSlot";

const Planification = ({ restaurantId }) => {
  const { setScheduledSlot,  setScheduledSlotDate} = useShopContext();
  const { availableSlots } = useSlots(restaurantId);
  const [selectedSlot, setSelectedSlot] = useState("");
  
  const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);

  const openScheduleModal = () => setScheduleModalIsOpen(true);
  const closeScheduleModal = () => setScheduleModalIsOpen(false);


 

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
    const parts = slot.split(" ");
    const dayPart = parts[0];
    const timePart = parts[1];
    const [hours, minutes] = timePart.split(":").map(Number);
    const slotDate = new Date();
    if (dayPart === "Tomorrow") {
        slotDate.setDate(slotDate.getDate() + 1);
    }
    slotDate.setHours(hours, minutes, 0, 0);
    setScheduledSlot(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
    setScheduledSlotDate(slotDate);
    closeScheduleModal();
  };
  
  



  return (
    <>
    <Flex
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      w="100%"
      onClick={openScheduleModal}>
      <Flex flex="1" >
        <Flex alignItems="center" gap={3}>
            <CalendarClock size={20}/>
            <h5>Schedule for later</h5>
        </Flex>
      </Flex>
      <Flex>
      <Text color="var(--grey500)">{selectedSlot || "Select a slot"}</Text>
      </Flex>
    </Flex>
    
    
    <SelectSlot
      isOpen={scheduleModalIsOpen}
      onClose={closeScheduleModal}
      availableSlots={availableSlots}
      onSlotSelect={handleSlotSelection}
    />
  </>
  );
};

export default Planification;
