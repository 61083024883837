import { useShopContext } from '../context/ShopContext';
import { Tooltip } from 'react-tooltip';
import { CircleHelp, CirclePlus, CircleMinus } from 'lucide-react';

const CartContent = ({ restaurantId }) => {
    
    
    const { cartItems, updateItemQuantity, calculateTotal, selectedLanguage, defaultLanguage, 
        shopSettings, deliveryMode, styles, tip, setTip, calculateMinimumOrderAmountDifference, 
        serviceFeeTTC, deliveryFee } = useShopContext();


    const increaseTip = () => {
        setTip((prevTip) => prevTip + 1);
    };
    const decreaseTip = () => {
        setTip((prevTip) => Math.max(0, prevTip - 1)); // Empêche le pourboire d'être négatif
    };

    const differenceToReachMinimum = calculateMinimumOrderAmountDifference();
    const minimumOrderAmount = deliveryMode === 'delivery'
        ? shopSettings.delivery.minimumOrderAmount
        : shopSettings.takeAway.minimumOrderAmount;
    
    
    return (      
        <><ul className={styles.cartList}>
        {cartItems.length > 0 ? cartItems.map((item, index) => (
            
            <li key={index} className={styles.cartItem}>
                
                {item.photo &&
                <div className={styles.itemImage}>
                <img src={item.photo} alt={item[`name_${selectedLanguage}`] || item[`name_${defaultLanguage}`]} />
                </div>
                }
                
                <div className={styles.itemDetails}>
                <div className={styles.cartItemTitle}>
                <div className={styles.itemName}>{item[`name_${selectedLanguage}`] || item[`name_${defaultLanguage}`]}
                {item.variation && 
                <span> - {item.variation.name}</span>
                }
                </div>
                
                <div className={styles.itemPrice}>
                    {(item.quantity * parseFloat(item.price.replace(',', '.'))).toFixed(2).replace('.', ',')}€
                </div>
                </div>



                {(item.extras?.length > 0 || item.note) && (
                
                <div className={styles.cartItemInfo}>
                
                
                {item.extras && item.extras.length > 0 && (
                    <div className={styles.extrasList}>
                       <h6>Extras</h6> 
                        {item.extras.map((extra, extraIndex) => (
                        <span key={extraIndex} className={styles.extra}>
                        {extra.name}{extraIndex < item.extras.length - 1 && <span className={styles.separator}> - </span>}
                        </span>
                        )
                    )}

                    </div>
                )}


                {item.note && 
                    <div className={styles.extrasList}><h6>Note</h6><span>{item.note}</span></div>
                }
                </div>
                )}
                <div className={styles.qty}> 
                    <CircleMinus size={24} color="var(--restaurantColor)" onClick={() => updateItemQuantity(item.itemId, item.variation?.name, item.quantity - 1, item.note, item.extras)}  /> 
                    <span className={styles.qtyNumber}>{item.quantity}</span>
                    <CirclePlus size={24} color="var(--restaurantColor)" onClick={() => updateItemQuantity(item.itemId, item.variation?.name, item.quantity + 1, item.note, item.extras)}  /> 
                </div>
                </div>
            </li>
        )) : <p>Your bag is empty.</p>
        
        }
        </ul>

        {cartItems.length > 0 &&
       <>
       <ul className={styles.cartFooter}>
            <li><label>Subtotal</label><div className={styles.itemPrice}>{calculateTotal().replace('.', ',')}€</div></li>
            <li>
                <label>Tip 
                <CircleHelp color='var(--grey500)' size={16} data-tooltip-id="tipTooltip" data-tooltip-content="The tips that you generously provide are distributed monthly among the employees of the restaurant." data-tooltip-place="top" />
                <Tooltip id="tipTooltip" place="top" className={styles.toolTip}></Tooltip>
                </label>
                <div className={styles.qty}>
                    <CircleMinus size={20} color="var(--restaurantColor)" onClick={decreaseTip}  /> 
                    <CirclePlus size={20} color="var(--restaurantColor)" onClick={increaseTip}  /> 
                    <div className={styles.itemPrice}>{`${tip},00€`}</div>
                </div> 
            </li>
            
           
            {deliveryMode === 'delivery' && deliveryFee > 0 &&
            <li><label>Delivery fee
            </label>
                <div className={styles.itemPrice}>{deliveryFee.toFixed(2).replace(".", ",")}€</div>
            </li>
            }
            
            {serviceFeeTTC > 0 &&
            <li><label>Service fee
            <CircleHelp color='var(--grey500)' size={16} data-tooltip-id="serviceFee" data-tooltip-content="This allows us to enhance your experience on our website, develop the mobile app and offer you a good customer support. " data-tooltip-place="top" />
            <Tooltip id="serviceFee" place="top" className={styles.toolTip}></Tooltip>
            </label>
                <div className={styles.itemPrice}>{serviceFeeTTC.toFixed(2).replace('.', ',')}€</div>
            </li>
            }
            
            
            
        </ul>
        {differenceToReachMinimum > 0 &&
            <div className={styles.errorMessage}>
                Just {differenceToReachMinimum.toFixed(2).replace('.', ',')}€ more to reach the {minimumOrderAmount.replace('.', ',')}€ minimum order amount for {deliveryMode}.
            </div>
        }
        </>
        }
        </>
    );
};

export default CartContent;

  
