import { useLocation } from "react-router-dom"; // Importer useLocation pour accéder aux données passées
import { useShopContext } from "./context/ShopContext";

const Success = ({ restaurantId }) => {
  const { styles } = useShopContext();
  const { state } = useLocation();

  console.log("state", state)

  return (
    <section className={`${styles.wrapper} centered`}>
      <div className={styles.container}>
        
        <h1>Thanks for your order</h1>

        <div className="columns">
          {state && (
            <>
              <div className={`${styles.checkoutColumn} column`}>
                <div className="widget">
                  <h5>Order details</h5>
                  <div className="widgetBody">
                    <p>ID {state.orderId}</p>
                    <p>Total {state.total}€</p>
                    <p>Delivery mode : {state.deliveryMode}</p>
                    <p>
                      Date {" "}
                      {new Date(state.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                {state.customerDetails && (
                  <>
                    <h5>Informations de livraison:</h5>
                    <p>Payment method {state.paymentMethod}</p>
                    <p>
                      Nom : {state.customerDetails.firstName}{" "}
                      {state.customerDetails.lastName}
                    </p>
                    {state.customerDetails.address && 
                    <p>
                      Adresse : {state.customerDetails.address},{" "}
                      {state.customerDetails.postalCode}{" "}
                      {state.customerDetails.city}
                    </p>
                    }
                    <p>Email : {state.customerDetails.email}</p>
                    <p>Téléphone : {state.customerDetails.phone}</p>
                  </>
                )}
              </div>
              <div className="column">
                <h5>Order status</h5>
                <p><span className="tag">{state.status}</span></p>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Success;
