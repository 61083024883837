import { useShopContext } from "../context/ShopContext";
import { Box } from "@chakra-ui/react";
const DeliveryModeSwitcher = ({ restaurantId }) => {
  
  const {
    styles,
    shopSettings,
    deliveryMode, 
    handleDeliveryModeChange 
  } = useShopContext();

  const activeCursorStyle = {
    transition: 'all .5s ease',
    left: deliveryMode === "delivery" ? '50%' : '4px',
  };


  return (
   
              
              <div className={styles.deliveryMode}>
                
              
              {shopSettings.takeAway.activation === true &&
                 <> 
                 <input
                  type="radio"
                  name="deliveryMode"
                  value="pickup"
                  id="pickup"
                  checked={deliveryMode === "pickup"}
                  onChange={handleDeliveryModeChange}
                />
                <label htmlFor="pickup">Pickup</label>
                </>
                }
                {shopSettings.delivery.activation === true && 
                  <>
                    <input
                      type="radio"
                      name="deliveryMode"
                      value="delivery"
                      id="delivery"
                      checked={deliveryMode === "delivery"}
                      onChange={handleDeliveryModeChange}
                    />
                    <label htmlFor="delivery">Delivery</label>
                  </>
                }
                <Box className={styles.activeCursor} style={activeCursorStyle}  w={(shopSettings.takeAway.activation && shopSettings.delivery.activation) ? "calc(50% - 4px)" : "calc(100% - 8px)"}></Box>
              </div>
           
  );
};

export default DeliveryModeSwitcher;
