import { Flex, Skeleton, Grid, SkeletonText } from "@chakra-ui/react";


function SkeletonLayout() {
  

  return (
  <Flex direction="row" gap={8}>
      
        <Flex direction="column" gap={12} flex={1} w="100%">
          <Flex direction={"column"} gap={4}>
            <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='5' w={"110px"} />
            <Grid templateColumns={{ xl: '1fr 1fr 1fr', large: "1fr 1fr", mobile: "1fr"}} gap={8}> 
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
            </Grid>
          </Flex>
          <Flex direction={"column"} gap={4}>
            <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='5' w={"110px"} />
            <Grid templateColumns={{ xl: '1fr 1fr 1fr', large: "1fr 1fr", mobile: "1fr"}} gap={8}> 
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
            </Grid>
          </Flex>
          <Flex direction={"column"} gap={4}>
            <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='5' w={"110px"} />
            <Grid templateColumns={{ xl: '1fr 1fr 1fr', large: "1fr 1fr", mobile: "1fr"}} gap={8}> 
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
              <Skeleton height='110px' borderRadius="12px" />
            </Grid>
          </Flex>
          
        </Flex>
        <Flex as="aside" flex={{ desktop: "0 0 320px", mobile: "1" }} direction="column" gap={8} display={{ desktop: "flex", mobile: "none"}}>
            <Skeleton height='320px' w="100%" borderRadius="12px" />
        </Flex>
  </Flex>
  );
}
export default SkeletonLayout;
