import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import {
  collectionGroup,
  query,
  where,
  getDocs,
  orderBy,
  doc, getDoc
} from "firebase/firestore";
import { Container, Flex, Box, Image, Link, Divider } from "@chakra-ui/react";
import { format } from "date-fns";
import OrderInvoice from "./OrderInvoice";
import { pdf } from "@react-pdf/renderer";

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const { currentUser } = useAuth();
  const [generalSettings, setGeneralSettings ] = useState([]);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      const settingsRef = doc(db, "general/settings");
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setGeneralSettings(data);
      } else {
        console.log("No such document!");
      }
    };
    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        const ordersQuery = query(
          collectionGroup(db, "orders"),
          orderBy("createdAt", "desc"),
          where("customerDetails.userId", "==", currentUser.uid)
        );
        const querySnapshot = await getDocs(ordersQuery);
        const fetchedOrders = querySnapshot.docs.map((doc) => {
          const orderData = doc.data();
          const fullDate = orderData.createdAt.toDate();
          return {
            id: doc.id,
            fullDate: format(fullDate, "d MMM yyyy 'at' HH:mm"),
            ...orderData,
          };
        });
        setOrders(fetchedOrders);
      }
    };

    fetchOrders();
  }, [currentUser]);

 

  const openInvoice = async (order) => {
    const blob = await pdf(
      <OrderInvoice order={order} generalSettings={generalSettings} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    
    // Forcer le téléchargement
    const link = document.createElement('a');
    link.href = url;
    link.download = `Invoice-${order.id}.pdf`; // Nom de fichier personnalisé
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getTotalItemsCount = (order) => {
    return order.cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const findCartItemPhoto = (cartItems) => {
    const itemWithPhoto = cartItems.find((item) => item.photo);
    return itemWithPhoto ? itemWithPhoto.photo : null;
  };

  return (
    <main>
      <Container maxW="1920px" p={[8, 16]}>
        <Flex gap={8} direction="column">
          <h2>Your orders</h2>
          {orders.length > 0 ? (
            <Flex direction="column" gap={8}>
              {orders.map((order) => {
                const photoSrc =
                  findCartItemPhoto(order.cartItems) ||
                  order.restaurantDetails.logo;
                return (
                  <><Flex key={order.id} direction="row" gap={4} align="center">
                    <Box
                      style={{
                        width: "100px",
                        height: "100px",
                        minWidth: "100px",
                        backgroundColor: "var(--grey100)",
                        borderRadius: "8px",
                        overflow: 'hidden'
                      }}>
                      <Image src={photoSrc} style={{ width: "100%", height: "100%", objectFit: 'cover' }} />
                    </Box>
                    <Flex direction="column">
                      <h5 style={{ textTransform: "capitalize" }}>
                        <Link href={`/${order.restaurantId}/shop`}> {order.restaurantId}</Link>
                        {" "}<span style={{ color: "var(--grey500)" }}>
                        • Order #{order.id}
                        </span>{" "}
                        • {order.status}
                      </h5>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "var(--grey600)",
                          whiteSpace: "nowrap",
                        }}>
                        {getTotalItemsCount(order)} items for {order.total}€ •{" "}
                        {order.fullDate}
                        <Flex direction="row" flexWrap="wrap">
                          <Link
                            style={{
                              color: "var(--brand)",
                              fontSize: "14px",
                              fontWeight: "500",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}>
                            View details
                          </Link>
                          {order.status === "completed" &&
                            order.paymentStatus === "Paid" && (
                              <>
                                <span style={{ margin: "0 4px" }}>•</span>

                                <div
                                  style={{
                                    color: "#121212",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    fontWeight: "500",
                                  }}
                                  onClick={() => openInvoice(order)}>
                                  Download invoice
                                </div>
                              </>
                            )}
                          
                        </Flex>
                      </p>
                    </Flex>
                              </Flex>
                   <Divider orientation='horizontal' />       
                   </>
                );
              })}
            </Flex>
          ) : (
            <p>Aucune commande trouvée.</p>
          )}
        </Flex>
      </Container>
    </main>
  );
};

export default UserOrders;
