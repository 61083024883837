import { useShopContext } from "../../context/ShopContext";
import { Flex } from "@chakra-ui/react";
import { Zap } from "lucide-react";



const NextAvailableSlot = ({ restaurantId }) => {
  const { nextAvailableSlot, nextSlotDate } = useShopContext();
    
  
  
  return (
    <>
      {nextAvailableSlot && (
        <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} w="100%">
          <Flex alignItems="center" gap={3}>
            <Zap size={20}/>
            <h5>Next availability</h5>
          </Flex>
          <Flex color="var(--grey500)" direction={"row"} gap={1}>
            {nextAvailableSlot}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NextAvailableSlot;
