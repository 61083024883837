import React, { useState, useEffect, useRef } from "react";
import useAllergenes from "../../../hooks/useAllergenes";
import useTranslations from "../../hooks/useTranslations";
import Modal from 'react-modal';
import { Flex, Box } from "@chakra-ui/react";

Modal.setAppElement('#root');

const modalStyles = {
  content: {
    width: '100%',
    maxWidth: '480px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99',
    width: '100%',
  },
};

const ItemModal = ({ isOpen, item, onClose, currentMainCategory, category, styles, selectedLanguage, defaultLanguage, extrasGroups }) => {

  const [mobileModalStyles, setMobileModalStyles] = useState(modalStyles);
  

  useEffect(() => {
    function handleResize() {
      setMobileModalStyles(prevStyles => ({
        ...prevStyles,
        overlay: {
          ...prevStyles.overlay,
          alignItems: window.innerWidth <= 767 ? 'flex-end' : 'center',
        },
      }));
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const allergenes = useAllergenes();
  const { translations } = useTranslations();
  
 
    
  const itemAllergenes = item.allergenes.map(allergeneName => 
    allergenes.find(allergene => allergene.name_en === allergeneName) || { name: "ask waiter", name_en: "ask waiter" }
  );

  const modalContentRef = useRef(null);
  const modalHeaderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  

useEffect(() => {
  const handleScroll = () => {
    if (modalContentRef.current && modalHeaderRef.current) {
      if (modalContentRef.current.scrollTop > 15) {
        modalHeaderRef.current.style.backgroundColor = 'var(--background-color)';
        setIsScrolled(true); 
      } else {
        modalHeaderRef.current.style.backgroundColor = '';
        setIsScrolled(false); 
      }
    }
  };
  const modalContentElement = modalContentRef.current;
  if (modalContentElement) {
    modalContentElement.addEventListener('scroll', handleScroll);

    return () => {
      if (modalContentElement) {
        modalContentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }
}, [isOpen]);
  
const applicableExtrasGroups = extrasGroups.filter(
  (group) =>
    group.applicableToOptions.includes(item.id) ||
    group.applicableToOptions.includes(category.id)
);

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={styles.modal}
        style={mobileModalStyles}>
        <div
          ref={modalContentRef}
          className={`${styles.modalContent} ${styles.itemModal}`}>
          <div ref={modalHeaderRef} className={styles.modalHeader}>
            <span className={styles.close} onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className={styles.itemName}>
              <h3
                style={{
                  color: isScrolled ? "var(--restaurantColor)" : "white",
                }}>
                {item[`name_${selectedLanguage}`] ||
                  item[`name_${defaultLanguage.value}`]}
              </h3>
            </div>
          </div>

          {(currentMainCategory.imageDisplay || category.style === "Cards") &&
            item.photo && (
              <div className={styles.itemImage}>
                <img
                  src={item.photo}
                  alt={
                    item[`name_${selectedLanguage}`] ||
                    item[`name_${defaultLanguage.value}`]
                  }
                />
              </div>
            )}

          <div className={styles.itemInfo}>
            {item[`description_${selectedLanguage}`] && (
              <>
                <div className={styles.head}>
                  <div className={styles.modalBlock}>
                    <h6>Description</h6>
                    <p>
                      {item[`description_${selectedLanguage}`] ||
                        item[`description_${defaultLanguage.value}`]}
                    </p>
                  </div>
                </div>
              </>
            )}

            {!category.formula && (
              <>
                <div className={styles.separator}></div>

                <div className={styles.priceRow}>
                  <h6>
                    {item.type === "variable"
                      ? translations.options[selectedLanguage]
                      : translations.price[selectedLanguage]}
                  </h6>
                  {item.type === "variable" ? (
                    <div className={styles.variations}>
                      {item.variations.map((variation, index) => (
                        <div className={styles.variation} key={index}>
                          <span className={styles.variationName}>
                            {variation.name}
                          </span>
                          <span className={styles.price}>
                            {variation.price}€
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className={styles.price}>{item.price}€</span>
                  )}
                </div>
              </>
            )}

            {(item.tags.length > 0 || item[`note_${defaultLanguage.value}`]) && (
              <>
                <div className={styles.separator}></div>
                <div className={styles.tags}>
                  {item[`note_${defaultLanguage.value}`] && (
                    <div className={styles.note}>
                      {item[`note_${selectedLanguage}`] ||
                        item[`note_${defaultLanguage.value}`]}
                    </div>
                  )}

                  {item.tags && item.tags.length > 0 && (
                    <>
                      {item.tags.map((tag, index) => (
                        <span key={index} className={styles.tag}>
                          {tag.label}
                        </span>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}

            {applicableExtrasGroups &&
              applicableExtrasGroups.map((group) => (
                <>
                  <div className={styles.separator}></div>

                  <React.Fragment key={group.id}>
                    <Flex direction="column" gap={2}>
                      <h6>{group.name}</h6>
                      <Flex direction="column" gap={1}>
                        {group.items.map((extra) => (
                          <Flex
                            direction="row"
                            key={extra.name}
                            className={styles.option}
                            justifyContent="space-between">
                            <div>
                              <div className={styles.optionName}>
                                {extra.name}
                              </div>
                            </div>
                            <span className={styles.optionPrice}>
                              {extra.price === 0 || extra.price === "0"
                                ? "Free"
                                : `+${extra.price
                                    .toFixed(2)
                                    .replace(".", ",")}€`}
                            </span>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </React.Fragment>
                </>
              ))}

            <div className={styles.separator}></div>
            <div className={`${styles.allergenes} ${styles.modalBlock}`}>
              <h6>{translations.allergens[selectedLanguage]}</h6>
              {itemAllergenes.length > 0 ? (
                <div className={styles.allergenesList}>
                  {itemAllergenes.some(
                    (allergene) => allergene.name === "ask waiter"
                  ) ? (
                    <p>{translations.askWaiter[selectedLanguage]}</p>
                  ) : (
                    itemAllergenes.map((allergene) => (
                      <Box key={allergene.id} className={styles.allergene}>
                        <img
                          src={allergene.colorIcon}
                          className={styles.icon}
                          alt={
                            allergene[`name_${selectedLanguage}`] ||
                            allergene.name_en
                          }
                        />
                        <span>
                          {allergene[`name_${selectedLanguage}`] ||
                            allergene.name_en}
                        </span>
                      </Box>
                    ))
                  )}
                </div>
              ) : (
                <p>{translations.noAllergens[selectedLanguage]}</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  
  export default ItemModal;
  