// ShopActivationRoute.js
import useSettings from '../frontend/hooks/useSettings';
import ShopFrontend from './ShopFrontend';
import NotFound from '../NotFound';
import { useShopContext } from './context/ShopContext';
import Spinner from "../components/Spinner";


const ShopActivationRoute = ({ restaurantId }) => {
  const { isLoading } = useSettings(restaurantId);
  const { shopSettings } = useShopContext();
  

  if (isLoading) {
    return <Spinner/>; 
  } else if ( shopSettings.delivery?.activation || shopSettings.takeAway?.activation) {
    return <ShopFrontend restaurantId={restaurantId} />;
  } else {
    return <NotFound restaurantId={restaurantId} />;
  }
};

export default ShopActivationRoute;
