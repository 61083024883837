/* global google */
import React, { useEffect, useRef, useState } from 'react';

const Map = ({ address, title }) => {
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapContainerStyle = { width: '100%', height: '250px' };

  // Load the map and set up the marker upon address change or once the map script is loaded
  useEffect(() => {
    if (!window.google || !address || mapLoaded) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        const mapOptions = {
          zoom: 14,
          center: location,
          mapTypeControl: false, // Enlève les boutons de type de carte
          fullscreenControl: false, // Enlève le bouton de plein écran
          streetViewControl: false,
        };

        const map = new window.google.maps.Map(mapRef.current, mapOptions);
        setMapLoaded(true);

        new google.maps.Marker({
          position: location,
          map: map,
          title: title,
        });
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  }, [address]);

  return <div ref={mapRef} style={mapContainerStyle} />;
};

export default Map;
